import VendorReports from "../components/Reports/VendorReports";

function Reports({isAdmin}) {
    return (
        <div>
            <h1 className="text-2xl py-4">Reports</h1>
            <VendorReports isAdmin={isAdmin} />
        </div>
    );
}

export default Reports;