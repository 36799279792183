import React, { useEffect, useState } from "react";
import ExcelJS from "exceljs";

import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import Stack from "@mui/material/Stack";
import ArticleIcon from "@mui/icons-material/Article";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { toast, Toaster } from "react-hot-toast";
import { customersArrayData } from "../../constant/customersArrayData";
import { customersObjectData } from "../../constant/customersObjectData";
import { exportToPDF } from "./exportToPDF";
import { PictureAsPdf } from "@mui/icons-material";
import { FetchPharmacies } from "../Catalogue/Api/pharmaciesApi";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

let userFullId = localStorage.getItem("userId");
userFullId = userFullId?.split("-")[0];

const VendorsReports = ({ isAdmin }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedPharmacy, setSelectedPharmacy] = useState("");
  const [allBrands, setAllBrands] = useState([]);
  const [allPharmacy, setAllPharmacy] = useState([]);
  const [vendorId, setVendorId] = useState("");
  const [selectedCompareOption, setSelectedCompareOption] = useState("");
  const [firstMonth, setFirstMonth] = useState("");
  const [secondMonth, setSecondMonth] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/get-brands?id=${userFullId}`)
      .then((res) => {
        const allPartList = res?.data;
        const uniqueBrands = [
          ...new Set(allPartList.map((item) => item.DEXT_BRAND)),
        ];
        setAllBrands(uniqueBrands);
      });
  }, []);

  const fetchAllPharmacy = async () => {
    const pharmacyList = await FetchPharmacies(userFullId);
    const modifiedData = [
      ...new Map(
        pharmacyList
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((item) => [item.label, item]) // Use label as the key to ensure uniqueness
      ).values(),
    ];
    const filteredData = modifiedData.filter((item) =>
      item?.Code.includes("C")
    );
    setAllPharmacy(filteredData);
  };

  useEffect(() => {
    fetchAllPharmacy();
  }, []);

  const handleGenerateAdminReport = async (
    userFullId,
    selectedOption,
    startDate,
    endDate
  ) => {
    console.log(
      `Generating ${selectedOption} report from ${startDate} to ${endDate}...`
    );
    const vendorsId = ["V1059", "V1131"];
    const loggedInUser = localStorage.getItem("userId");
    const url = new URL(
      loggedInUser === "S0001"
        ? `${process.env.REACT_APP_API_URL}/get-vendor-report`
        : `${process.env.REACT_APP_API_URL}/get-customer-report`
    );

    url.searchParams.set(
      "id",
      loggedInUser === "S0002" ? customersArrayData : vendorsId
    );
    url.searchParams.set("opt", selectedOption);
    if (
      selectedOption !== "productsCsv" &&
      selectedOption !== "catalogueNoStock" &&
      selectedOption !== "productsExpired"
    ) {
      url.searchParams.set("str", startDate);
      url.searchParams.set("end", endDate);
    }

    try {
      const loadingToast = toast.loading("Generating report...");

      const response = await axios.get(url, {
        timeout: 5000000,
      });

      toast.dismiss(loadingToast);

      console.log(response.data, "response report");

      if (response.data.length === 0) {
        toast.error("No results for this date and report option");
        return;
      }
      toast.success("Successfully generated!");

      const workbook = new ExcelJS.Workbook();
      const dataArray = response.data;

      //not used
      if (selectedOption == "SalesByCPX") {
        Object.keys(dataArray).forEach((city) => {
          const worksheet = workbook.addWorksheet(city);

          const cityData = dataArray[city];

          const headerRow = [
            "CUSTDES",
            "QTY",
            "Gross_value",
            "Discount",
            "over_all_discount",
            "VAT",
            "TOTAL_VALUE_INCL_VAT",
          ];
          worksheet.addRow(headerRow);

          const keyDataTypes = {
            CUSTDES: ExcelJS.ValueType.String,
            Discount: ExcelJS.ValueType.Number,
            Gross_value: ExcelJS.ValueType.Number,
            QTY: ExcelJS.ValueType.Number,
            TOTAL_VALUE_INCL_VAT: ExcelJS.ValueType.Number,
            VAT: ExcelJS.ValueType.Number,
            over_all_discount: ExcelJS.ValueType.Number,
            XT: ExcelJS.ValueType.Number,
            Purchase_amount: ExcelJS.ValueType.Number,
            Purchase_Amount: ExcelJS.ValueType.Number,
            PURCHASE_AMOUNT: ExcelJS.ValueType.Number,
            NetValue: ExcelJS.ValueType.Number,
            Net_Value: ExcelJS.ValueType.Number,
            TotalAmount: ExcelJS.ValueType.Number,
          };

          cityData.forEach((record) => {
            console.log(record, "record 2");
            const headerArray = Object.keys(record);
            console.log(Object.values(record), "Object.values(record) 2");
            console.log(Object.keys(record), "Object.values(record) 2");

            const valuesArray = headerArray.map((key) => {
              const dataType = keyDataTypes[key] || ExcelJS.ValueType.String;

              if (dataType === ExcelJS.ValueType.Number) {
                return parseFloat(record[key]);
              } else {
                return record[key];
              }
            });
            worksheet.addRow(Object.values(valuesArray));
          });

          headerRow.forEach((columnName, index) => {
            const column = worksheet.getColumn(index + 1);

            if (columnName != "CUSTDES") {
              column.numFmt = "#,##0.00";
            } else {
              column.numFmt = "General";
            }
          });
        });
      } else {
        const worksheet = workbook.addWorksheet("Sheet 1");

        const headerArray = Object.keys(dataArray[0]);
        worksheet.addRow(headerArray);
        const firstRow = worksheet.getRow(1);
        firstRow.eachCell((cell) => {
          cell.font = { bold: true };
        });
        worksheet.autoFilter = {
          from: {
            row: 1,
            column: 1,
          },
          to: {
            row: 1,
            column: headerArray.length,
          },
        };

        const keyDataTypes = {
          "KEDIFAP CODE": ExcelJS.ValueType.String,
          DESCRIPTION: ExcelJS.ValueType.String,
          CATEGORY: ExcelJS.ValueType.String,
          NARCOTIC: ExcelJS.ValueType.String,
          GHS: ExcelJS.ValueType.String,
          LIQUID: ExcelJS.ValueType.String,
          FRAGILE: ExcelJS.ValueType.String,
          FRIDGE: ExcelJS.ValueType.String,
          BRAND: ExcelJS.ValueType.String,
          BARCODE: ExcelJS.ValueType.String,
          RETAIL: ExcelJS.ValueType.Number,
          WHOLESALE: ExcelJS.ValueType.Number,
          "QUOTA QTY": ExcelJS.ValueType.Number,
          "AVAILABLE STOCK": ExcelJS.ValueType.Number,
          "RECEIVING QTY": ExcelJS.ValueType.Number,
          CUSTDES: ExcelJS.ValueType.String,
          Discount: ExcelJS.ValueType.Number,
          Gross_value: ExcelJS.ValueType.Number,
          QTY: ExcelJS.ValueType.Number,
          TOTAL_VALUE_INCL_VAT: ExcelJS.ValueType.Number,
          VAT: ExcelJS.ValueType.Number,
          over_all_discount: ExcelJS.ValueType.Number,
          XT: ExcelJS.ValueType.Number,
          Purchase_amount: ExcelJS.ValueType.Number,
          Purchase_Amount: ExcelJS.ValueType.Number,
          PURCHASE_AMOUNT: ExcelJS.ValueType.Number,
          NetValue: ExcelJS.ValueType.Number,
          Net_Value: ExcelJS.ValueType.Number,
          TotalAmount: ExcelJS.ValueType.Number,
        };

        if (loggedInUser === "S0002") {
          dataArray.forEach((data) => {
            const valuesArray = headerArray.map((key) => {
              const dataType = keyDataTypes[key] || ExcelJS.ValueType.String;

              if (key !== "QTY" && dataType === ExcelJS.ValueType.Number) {
                return parseFloat(data[key]);
              } else if (
                key === "NARCOTIC" ||
                key === "GHS" ||
                key === "LIQUID" ||
                key === "FRAGILE" ||
                key === "FRIDGE"
              ) {
                // Use the key directly to check the condition
                const value = data[key] === "0" ? "No" : "Yes"; // Check for string "0"
                return value;
              } else {
                const value = data[key];
                return key === "USER_ID" ? customersObjectData[value] : value;
              }
            });

            worksheet.addRow(valuesArray);
          });
        } else {
          dataArray.forEach((data) => {
            const valuesArray = headerArray.map((key) => {
              const dataType = keyDataTypes[key] || ExcelJS.ValueType.String;

              if (key !== "QTY" && dataType === ExcelJS.ValueType.Number) {
                return parseFloat(data[key]);
              } else if (
                key === "NARCOTIC" ||
                key === "GHS" ||
                key === "LIQUID" ||
                key === "FRAGILE" ||
                key === "FRIDGE"
              ) {
                // Use the key directly to check the condition
                const value = data[key] === "0" ? "No" : "Yes"; // Check for string "0"
                return value;
              } else {
                const value = data[key];
                return key === "CustCode"
                  ? customersObjectData[value] || value
                  : value;
              }
            });

            worksheet.addRow(valuesArray);
          });
        }

        headerArray.forEach((columnName, index) => {
          const column = worksheet.getColumn(index + 1);

          if (columnName == "EXPIRY DATE") {
            column.numFmt = "m/d/yyyy";
          } else if (
            columnName != "PARTNAME" &&
            columnName != "KEDIFAP CODE" &&
            columnName != "DESCRIPTION" &&
            columnName != "CATEGORY" &&
            columnName != "CUSTDES" &&
            columnName != "BRAND" &&
            columnName != "CITY" &&
            columnName != "NARCOTIC" &&
            columnName != "GHS" &&
            columnName != "LIQUID" &&
            columnName != "FRAGILE" &&
            columnName != "FRIDGE"
          ) {
            column.numFmt = "#,##0.00";
          } else {
            column.numFmt = "general";
          }
        });
      }

      const blob = await workbook.xlsx.writeBuffer();

      const link = document.createElement("a");
      link.href = URL.createObjectURL(new Blob([blob]));
      link.download = `${
        loggedInUser === "S0002" ? "Customer" : userFullId || "Vendor"
      }_invoice_${selectedOption}.xlsx`;
      link.click();
    } catch (error) {
      toast.dismiss();
      toast.error("Error: ", error);
      console.error(error);
    }
  };

  const handleGenerateReport = async (
    userFullId,
    selectedOption,
    startDate,
    endDate,
    isPdf
  ) => {
    console.log(
      `Generating ${selectedOption} report from ${startDate} to ${endDate}...`
    );
    let url = new URL(`${process.env.REACT_APP_API_URL}/get-new-report`);

    if (
      selectedOption !== "productsCsv" &&
      selectedOption !== "catalogueNoStock" &&
      selectedOption !== "productsExpired" &&
      selectedOption !== "sluggishProducts"
    ) {
      url = new URL(`${process.env.REACT_APP_API_URL}/get-sales-report`);
      url.searchParams.set("str", startDate);
      url.searchParams.set("end", endDate);
      url.searchParams.set("brand", selectedBrand);
      url.searchParams.set("firmName", selectedPharmacy);
    }
    url.searchParams.set("id", userFullId);
    url.searchParams.set("opt", selectedOption);

    try {
      const loadingToast = toast.loading("Generating report...");

      const response = await axios.get(url, {
        timeout: 5000000,
      });

      toast.dismiss(loadingToast);

      console.log(response.data, "response report");

      if (response.data.length === 0) {
        toast.error("No results for this date and report option");
        return;
      }
      toast.success("Successfully generated!");

      const workbook = new ExcelJS.Workbook();
      const dataArray = response.data;

      // Total variables
      let totalQty = 0;
      let totalValueInclVat = 0;
      let qtyColumnIndex = -1;
      let totalValueColumnIndex = -1;

      //not used
      if (selectedOption == "SalesByCPX") {
        Object.keys(dataArray).forEach((city) => {
          const worksheet = workbook.addWorksheet(city);

          const cityData = dataArray[city];

          const headerRow = [
            "CUSTDES",
            "QTY",
            "Gross_value",
            "Discount",
            "over_all_discount",
            "VAT",
            "TOTAL_VALUE_INCL_VAT",
          ];

          // Find indexes for QTY and TOTAL_VALUE_INCL_VAT in the header row
          qtyColumnIndex = headerRow.indexOf("QTY");
          totalValueColumnIndex = headerRow.indexOf("TOTAL_VALUE_INCL_VAT");
          worksheet.addRow(headerRow);

          const keyDataTypes = {
            CUSTDES: ExcelJS.ValueType.String,
            Discount: ExcelJS.ValueType.Number,
            Gross_value: ExcelJS.ValueType.Number,
            QTY: ExcelJS.ValueType.Number,
            TOTAL_VALUE_INCL_VAT: ExcelJS.ValueType.Number,
            VAT: ExcelJS.ValueType.Number,
            over_all_discount: ExcelJS.ValueType.Number,
            XT: ExcelJS.ValueType.Number,
            Purchase_amount: ExcelJS.ValueType.Number,
            Purchase_Amount: ExcelJS.ValueType.Number,
            PURCHASE_AMOUNT: ExcelJS.ValueType.Number,
            NetValue: ExcelJS.ValueType.Number,
            Net_Value: ExcelJS.ValueType.Number,
            NET_VALUE: ExcelJS.ValueType.Number,
            TotalAmount: ExcelJS.ValueType.Number,
          };

          cityData.forEach((record) => {
            console.log(record, "record 2");
            const headerArray = Object.keys(record);

            const valuesArray = headerArray.map((key) => {
              const dataType = keyDataTypes[key] || ExcelJS.ValueType.String;

              if (dataType === ExcelJS.ValueType.Number) {
                return parseFloat(record[key]);
              } else {
                return record[key];
              }
            });
            // Add to totals if relevant columns are found
            if (qtyColumnIndex !== -1 && !isNaN(valuesArray[qtyColumnIndex])) {
              totalQty += parseFloat(valuesArray[qtyColumnIndex]);
            }
            if (
              totalValueColumnIndex !== -1 &&
              !isNaN(valuesArray[totalValueColumnIndex])
            ) {
              totalValueInclVat += parseFloat(
                valuesArray[totalValueColumnIndex]
              );
            }
            worksheet.addRow(Object.values(valuesArray));
          });

          headerRow.forEach((columnName, index) => {
            const column = worksheet.getColumn(index + 1);

            if (columnName != "CUSTDES") {
              column.numFmt = "#,##0.00";
            } else {
              column.numFmt = "General";
            }
          });

          // Add totals row
          const totalRow = Array(headerRow.length).fill("");
          totalRow[0] = "Total";
          if (qtyColumnIndex !== -1) {
            totalRow[qtyColumnIndex] = totalQty;
          }
          if (totalValueColumnIndex !== -1) {
            totalRow[totalValueColumnIndex] = totalValueInclVat;
          }
          worksheet.addRow(totalRow);
        });
      } else {
        const worksheet = workbook.addWorksheet("Sheet 1");

        const headerArray = Object.keys(dataArray[0]);

        // Determine the columns for QTY and TOTAL_VALUE_INCL_VAT dynamically
        qtyColumnIndex = headerArray.indexOf("QTY");
        totalValueColumnIndex = headerArray.indexOf("TOTAL_VALUE_INCL_VAT");
        if (qtyColumnIndex === -1 || totalValueColumnIndex === -1) {
          console.log(
            "Column indexes not found. Check header array:",
            headerArray
          );
        }
        worksheet.addRow(headerArray);

        const keyDataTypes = {
          "KEDIFAP CODE": ExcelJS.ValueType.String,
          DESCRIPTION: ExcelJS.ValueType.String,
          CATEGORY: ExcelJS.ValueType.String,
          NARCOTIC: ExcelJS.ValueType.String,
          GHS: ExcelJS.ValueType.String,
          LIQUID: ExcelJS.ValueType.String,
          FRAGILE: ExcelJS.ValueType.String,
          FRIDGE: ExcelJS.ValueType.String,
          BRAND: ExcelJS.ValueType.String,
          BARCODE: ExcelJS.ValueType.String,
          RETAIL: ExcelJS.ValueType.Number,
          WHOLESALE: ExcelJS.ValueType.Number,
          "QUOTA QTY": ExcelJS.ValueType.Number,
          "AVAILABLE STOCK": ExcelJS.ValueType.Number,
          "RECEIVING QTY": ExcelJS.ValueType.Number,
          CUSTDES: ExcelJS.ValueType.String,
          Discount: ExcelJS.ValueType.Number,
          Gross_value: ExcelJS.ValueType.Number,
          QTY: ExcelJS.ValueType.Number,
          TOTAL_VALUE_INCL_VAT: ExcelJS.ValueType.Number,
          VAT: ExcelJS.ValueType.Number,
          over_all_discount: ExcelJS.ValueType.Number,
          XT: ExcelJS.ValueType.Number,
          Purchase_amount: ExcelJS.ValueType.Number,
          Purchase_Amount: ExcelJS.ValueType.Number,
          PURCHASE_AMOUNT: ExcelJS.ValueType.Number,
          NetValue: ExcelJS.ValueType.Number,
          Net_Value: ExcelJS.ValueType.Number,
          NET_VALUE: ExcelJS.ValueType.Number,
          TotalAmount: ExcelJS.ValueType.Number,
        };

        dataArray.forEach((data) => {
          const valuesArray = headerArray.map((key) => {
            const dataType = keyDataTypes[key] || ExcelJS.ValueType.String;
            if (dataType === ExcelJS.ValueType.Number) {
              return parseFloat(data[key]);
            } else if (
              key === "NARCOTIC" ||
              key === "GHS" ||
              key === "LIQUID" ||
              key === "FRAGILE" ||
              key === "FRIDGE"
            ) {
              // Use the key directly to check the condition
              const value = data[key] === "0" ? "No" : "Yes"; // Check for string "0"
              return value;
            } else {
              return data[key];
            }
          });

          // Update totals if relevant columns are found
          if (qtyColumnIndex !== -1 && !isNaN(valuesArray[qtyColumnIndex])) {
            totalQty += parseFloat(valuesArray[qtyColumnIndex]);
          }
          if (
            totalValueColumnIndex !== -1 &&
            !isNaN(valuesArray[totalValueColumnIndex])
          ) {
            totalValueInclVat += parseFloat(valuesArray[totalValueColumnIndex]);
          }

          worksheet.addRow(valuesArray);
        });
        // Add totals row
        const totalRow = Array(headerArray.length).fill("");
        totalRow[0] = "Total";
        if (qtyColumnIndex !== -1) totalRow[qtyColumnIndex] = totalQty;
        if (totalValueColumnIndex !== -1)
          totalRow[totalValueColumnIndex] = totalValueInclVat;
        worksheet.addRow(totalRow);

        headerArray.forEach((columnName, index) => {
          const column = worksheet.getColumn(index + 1);

          if (columnName == "EXPIRY DATE") {
            column.numFmt = "m/d/yyyy";
          } else if (
            columnName != "PARTNAME" &&
            columnName != "KEDIFAP CODE" &&
            columnName != "DESCRIPTION" &&
            columnName != "CATEGORY" &&
            columnName != "CUSTDES" &&
            columnName != "BRAND" &&
            columnName != "CITY" &&
            columnName != "NARCOTIC" &&
            columnName != "GHS" &&
            columnName != "LIQUID" &&
            columnName != "FRAGILE" &&
            columnName != "FRIDGE"
          ) {
            column.numFmt = "#,##0.00";
          } else {
            column.numFmt = "general";
          }
        });
      }
      if (isPdf) {
        exportToPDF(workbook, userFullId, selectedOption);
      } else {
        const blob = await workbook.xlsx.writeBuffer();

        const link = document.createElement("a");
        link.href = URL.createObjectURL(new Blob([blob]));
        link.download = `${userFullId}_invoice_${selectedOption}.xlsx`;
        link.click();
      }
    } catch (error) {
      toast.dismiss();
      toast.error(
        "Error: ",
        error || JSON.stringify(error?.message || error?.error)
      );
      console.error(error);
    }
  };

  const handleCompareReport = async () => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/compare-report`);

    url.searchParams.set("id", userFullId);
    url.searchParams.set("opt", selectedCompareOption);
    url.searchParams.set("brand", selectedBrand);
    url.searchParams.set("firmName", selectedPharmacy);
    if (
      selectedCompareOption !== "productsCsv" &&
      selectedCompareOption !== "catalogueNoStock" &&
      selectedCompareOption !== "productsExpired"
    ) {
      url.searchParams.set("firstMonth", firstMonth);
      url.searchParams.set("secondMonth", secondMonth);
    }

    try {
      const loadingToast = toast.loading("Generating report...");

      const response = await axios.get(url, {
        timeout: 5000000,
      });

      toast.dismiss(loadingToast);

      console.log(response.data, "response report");

      if (
        response.data?.firstMonth.length === 0 ||
        response.data?.secondMonth.length === 0
      ) {
        toast.error("No results for both month and report option");
        return;
      }
      toast.success("Successfully generated!");

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Comparison Report");

      const dataArrayFirst = response.data?.firstMonth;
      const dataArraySecond = response.data?.secondMonth;

      // Extract month and year
      const firstMonthYear = new Date(firstMonth).toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      const secondMonthYear = new Date(secondMonth).toLocaleString("default", {
        month: "long",
        year: "numeric",
      });

      // Adding headers
      const headerArray = [
        ...Object.keys(dataArrayFirst[0]),
        "",
        ...Object.keys(dataArraySecond[0]),
      ];
      const headerDateArray = Object.keys(dataArrayFirst[0]).map(() => "");
      worksheet.addRow([firstMonthYear, ...headerDateArray, secondMonthYear]);
      worksheet.addRow(headerArray);

      // Define the key data types
      const keyDataTypes = {
        "KEDIFAP CODE": ExcelJS.ValueType.String,
        DESCRIPTION: ExcelJS.ValueType.String,
        CATEGORY: ExcelJS.ValueType.String,
        NARCOTIC: ExcelJS.ValueType.String,
        GHS: ExcelJS.ValueType.String,
        LIQUID: ExcelJS.ValueType.String,
        FRAGILE: ExcelJS.ValueType.String,
        FRIDGE: ExcelJS.ValueType.String,
        BRAND: ExcelJS.ValueType.String,
        BARCODE: ExcelJS.ValueType.String,
        RETAIL: ExcelJS.ValueType.Number,
        WHOLESALE: ExcelJS.ValueType.Number,
        "QUOTA QTY": ExcelJS.ValueType.Number,
        "AVAILABLE STOCK": ExcelJS.ValueType.Number,
        "RECEIVING QTY": ExcelJS.ValueType.Number,
        CUSTDES: ExcelJS.ValueType.String,
        Discount: ExcelJS.ValueType.Number,
        Gross_value: ExcelJS.ValueType.Number,
        QTY: ExcelJS.ValueType.Number,
        TOTAL_VALUE_INCL_VAT: ExcelJS.ValueType.Number,
        VAT: ExcelJS.ValueType.Number,
        over_all_discount: ExcelJS.ValueType.Number,
        XT: ExcelJS.ValueType.Number,
        Purchase_amount: ExcelJS.ValueType.Number,
        Purchase_Amount: ExcelJS.ValueType.Number,
        PURCHASE_AMOUNT: ExcelJS.ValueType.Number,
        NetValue: ExcelJS.ValueType.Number,
        Net_Value: ExcelJS.ValueType.Number,
        NET_VALUE: ExcelJS.ValueType.Number,
        TotalAmount: ExcelJS.ValueType.Number,
      };

      // Adding data
      const maxLength = Math.max(dataArrayFirst.length, dataArraySecond.length);
      for (let i = 0; i < maxLength; i++) {
        const dataFirst = dataArrayFirst[i] || {};
        const dataSecond = dataArraySecond[i] || {};

        const valuesArrayFirst = Object.keys(dataArrayFirst[0]).map((key) => {
          const dataType = keyDataTypes[key] || ExcelJS.ValueType.String;
          if (dataType === ExcelJS.ValueType.Number) {
            return parseFloat(dataFirst[key]) || 0;
          } else if (
            ["NARCOTIC", "GHS", "LIQUID", "FRAGILE", "FRIDGE"].includes(key)
          ) {
            return dataFirst[key] === "0" ? "No" : "Yes";
          } else {
            return dataFirst[key] || "";
          }
        });

        const valuesArraySecond = Object.keys(dataArraySecond[0]).map((key) => {
          const dataType = keyDataTypes[key] || ExcelJS.ValueType.String;
          if (dataType === ExcelJS.ValueType.Number) {
            return parseFloat(dataSecond[key]) || 0;
          } else if (
            ["NARCOTIC", "GHS", "LIQUID", "FRAGILE", "FRIDGE"].includes(key)
          ) {
            return dataSecond[key] === "0" ? "No" : "Yes";
          } else {
            return dataSecond[key] || "";
          }
        });

        worksheet.addRow([...valuesArrayFirst, "", ...valuesArraySecond]);
      }

      // Formatting columns
      headerArray.forEach((columnName, index) => {
        const column = worksheet.getColumn(index + 1);
        if (columnName === "EXPIRY DATE") {
          column.numFmt = "m/d/yyyy";
        } else if (
          ![
            "CUSTDES",
            "DESCRIPTION",
            "CATEGORY",
            "BRAND",
            "CITY",
            "NARCOTIC",
            "GHS",
            "LIQUID",
            "FRAGILE",
            "FRIDGE",
          ].includes(columnName)
        ) {
          column.numFmt = "#,##0.00";
        } else {
          column.numFmt = "General";
        }
      });

      const blob = await workbook.xlsx.writeBuffer();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(new Blob([blob]));
      link.download = `${userFullId}_invoice_${selectedCompareOption}.xlsx`;
      link.click();
    } catch (error) {
      toast.dismiss();
      toast.error("Error: ", error);
      console.error(error);
    }
  };

  return (
    <>
      {!isAdmin ? (
        <Container
          maxWidth="lg"
          style={{
            display: "flex",
            flexDirection: window.innerWidth < 991 ? "column" : "row",
            gap: 50,
            paddingBottom: "10px",
          }}
        >
          <Box sx={{ width: window.innerWidth < 991 ? "100%" : "50%" }}>
            <Stack spacing={4}>
              <Typography variant="h4" align="center" gutterBottom>
                Report Generator
              </Typography>

              <FormControl
                variant="standard"
                fullWidth
                sx={{ marginBottom: 2 }}
              >
                <Item>
                  <InputLabel id="report-option-label">
                    Select Report Option
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="report-option-label"
                    id="report-option"
                    value={selectedOption}
                    label="Select Report Option"
                    onChange={(e) => setSelectedOption(e.target.value)}
                  >
                    <MenuItem value="SalesByTown">Sales by City</MenuItem>
                    <MenuItem value="SalesByCP">
                      Sales by City & Pharmacy
                    </MenuItem>
                    <MenuItem value="SalesByTownBrand">
                      Sales by City & Brand
                    </MenuItem>
                    <MenuItem value="SalesByTownProduct">
                      Sales by City by Product
                    </MenuItem>
                    <MenuItem value="SalesByCPPB">
                      Sales by City & Pharmacy & Product & Brand
                    </MenuItem>
                    <MenuItem value="SalesByPharmacy">
                      Sales by Pharmacy
                    </MenuItem>
                    <MenuItem value="SalesByPPB">
                      Sales by Pharmacy & product & brand
                    </MenuItem>
                    <MenuItem value="SalesByBrand">Sales by Brand</MenuItem>
                    <MenuItem value="SalesByBrandPharmacy">
                      Sales by Brand & Pharmacy
                    </MenuItem>
                    <MenuItem value="SalesByBCP">
                      Sales by Brand & City & Pharmacy
                    </MenuItem>
                    <MenuItem value="SalesByProduct">Sales by Product</MenuItem>
                    <MenuItem value="productsCsv">Available Stock</MenuItem>
                    <MenuItem value="productsExpired">
                      Products Expiring in 6 Months
                    </MenuItem>
                    <MenuItem value="catalogueNoStock">
                      Active Parts Catalogue
                    </MenuItem>
                    <MenuItem value="sluggishProducts">
                      Sluggish Products
                    </MenuItem>
                  </Select>
                </Item>
              </FormControl>
              {(selectedOption === "SalesByTownBrand" ||
                selectedOption === "SalesByBrandPharmacy" ||
                selectedOption === "SalesByBCP") && (
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                >
                  <Item>
                    <InputLabel id="report-brand-label">
                      Select Brand
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="report-brand-label"
                      id="report-brand"
                      value={selectedBrand}
                      label="Select Brand"
                      onChange={(e) => setSelectedBrand(e.target.value)}
                    >
                      {allBrands &&
                        allBrands.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </Item>
                </FormControl>
              )}
              {selectedOption === "SalesByPPB" && (
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                >
                  <Item>
                    <InputLabel id="report-brand-label">
                      Select Pharmacy
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="report-brand-label"
                      id="report-brand"
                      value={selectedPharmacy}
                      label="Select Brand"
                      onChange={(e) => setSelectedPharmacy(e.target.value)}
                    >
                      {allPharmacy &&
                        allPharmacy.map((item, index) => (
                          <MenuItem value={item.label} key={index}>
                            {item.label} ({item.Code})
                          </MenuItem>
                        ))}
                    </Select>
                  </Item>
                </FormControl>
              )}
              {selectedOption !== "sluggishProducts" && (
                <>
                  <Item>
                    <Typography
                      variant="standard"
                      sx={{ fontWeight: "bold", fontStyle: "italic" }}
                    >
                      From Date
                    </Typography>
                    <TextField
                      fullWidth
                      variant="standard"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      sx={{ marginBottom: 2 }}
                    />
                  </Item>
                  <Item>
                    <Typography
                      sx={{ fontWeight: "bold", fontStyle: "italic" }}
                    >
                      To Date
                    </Typography>
                    <TextField
                      variant="standard"
                      fullWidth
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      sx={{ marginBottom: 2 }}
                    />
                  </Item>
                </>
              )}
              {selectedOption === "sluggishProducts" && (
                <p className="text-center">
                  You will get report if stock is higher than 120 days of sales
                </p>
              )}
              <Item>
                {/* <Box textAlign="center"> */}
                <div className="flex flex-wrap justify-center gap-3">
                  <Button
                    variant="contained"
                    onClick={() =>
                      handleGenerateReport(
                        userFullId,
                        selectedOption,
                        startDate,
                        endDate
                      )
                    }
                    endIcon={<ArticleIcon />}
                  >
                    Generate Report
                  </Button>
                  {(selectedOption === "SalesByCPPB" ||
                    selectedOption === "SalesByPPB" ||
                    selectedOption === "SalesByPharmacy" ||
                    selectedOption === "SalesByCP") && (
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleGenerateReport(
                          userFullId,
                          selectedOption,
                          startDate,
                          endDate,
                          "isPdf"
                        )
                      }
                      endIcon={<PictureAsPdf />}
                    >
                      Generate PDF Report
                    </Button>
                  )}
                </div>
                <Toaster containerStyle={{ zIndex: 999999999999999 }} />
              </Item>
            </Stack>
          </Box>
          <Box sx={{ width: window.innerWidth < 991 ? "100%" : "50%" }}>
            <Stack spacing={4}>
              <Typography variant="h4" align="center" gutterBottom>
                Compare Report
              </Typography>

              <FormControl
                variant="standard"
                fullWidth
                sx={{ marginBottom: 2 }}
              >
                <Item>
                  <InputLabel id="report-option-label">
                    Select Report Option
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="report-option-label"
                    id="report-option"
                    value={selectedCompareOption}
                    label="Select Report Option"
                    onChange={(e) => setSelectedCompareOption(e.target.value)}
                  >
                    <MenuItem value="SalesByTown">Sales by City</MenuItem>
                    <MenuItem value="SalesByCP">
                      Sales by City & Pharmacy
                    </MenuItem>
                    <MenuItem value="SalesByTownBrand">
                      Sales by City & Brand
                    </MenuItem>
                    <MenuItem value="SalesByTownProduct">
                      Sales by City by Product
                    </MenuItem>
                    <MenuItem value="SalesByCPPB">
                      Sales by City & Pharmacy & Product & Brand
                    </MenuItem>
                    <MenuItem value="SalesByPharmacy">
                      Sales by Pharmacy
                    </MenuItem>
                    <MenuItem value="SalesByPPB">
                      Sales by Pharmacy & product & brand
                    </MenuItem>
                    <MenuItem value="SalesByBrand">Sales by Brand</MenuItem>
                    <MenuItem value="SalesByBrandPharmacy">
                      Sales by Brand & Pharmacy
                    </MenuItem>
                    <MenuItem value="SalesByBCP">
                      Sales by Brand & City & Pharmacy
                    </MenuItem>
                    <MenuItem value="SalesByProduct">Sales by Product</MenuItem>
                  </Select>
                </Item>
              </FormControl>
              {selectedCompareOption === "SalesByPPB" && (
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                >
                  <Item>
                    <InputLabel id="report-brand-label">
                      Select Pharmacy
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="report-brand-label"
                      id="report-brand"
                      value={selectedPharmacy}
                      label="Select Brand"
                      onChange={(e) => setSelectedPharmacy(e.target.value)}
                    >
                      {allPharmacy &&
                        allPharmacy.map((item, index) => (
                          <MenuItem value={item.label} key={index}>
                            {item.label} ({item.Code})
                          </MenuItem>
                        ))}
                    </Select>
                  </Item>
                </FormControl>
              )}
              {(selectedCompareOption === "SalesByTownBrand" ||
                selectedCompareOption === "SalesByBrandPharmacy" ||
                selectedCompareOption === "SalesByBCP") && (
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                >
                  <Item>
                    <InputLabel id="report-brand-label">
                      Select Brand
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="report-brand-label"
                      id="report-brand"
                      value={selectedBrand}
                      label="Select Brand"
                      onChange={(e) => setSelectedBrand(e.target.value)}
                    >
                      {allBrands &&
                        allBrands.map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </Item>
                </FormControl>
              )}
              <Item>
                <Typography
                  variant="standard"
                  sx={{ fontWeight: "bold", fontStyle: "italic" }}
                >
                  First Month
                </Typography>
                <TextField
                  fullWidth
                  variant="standard"
                  type="month"
                  value={firstMonth}
                  onChange={(e) => setFirstMonth(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
              </Item>
              <Item>
                <Typography sx={{ fontWeight: "bold", fontStyle: "italic" }}>
                  Second Month
                </Typography>
                <TextField
                  variant="standard"
                  fullWidth
                  type="month"
                  value={secondMonth}
                  onChange={(e) => setSecondMonth(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
              </Item>
              <Item>
                {/* <Box textAlign="center"> */}
                <Button
                  variant="contained"
                  onClick={() => handleCompareReport()}
                  endIcon={<ArticleIcon />}
                >
                  Generate Report
                </Button>
                <Toaster containerStyle={{ zIndex: 999999999999999 }} />
              </Item>
            </Stack>
          </Box>
        </Container>
      ) : (
        <Container
          maxWidth="lg"
          style={{
            paddingBottom: "10px",
            display: "flex",
            flexDirection: window.innerWidth < 991 ? "column" : "row",
            gap: 50,
            justifyContent: "center",
            marginBottom: 30,
          }}
        >
          <Box sx={{ width: window.innerWidth < 991 ? "100%" : "50%" }}>
            <Stack spacing={4}>
              <Typography variant="h4" align="center" gutterBottom>
                Report Generator
              </Typography>

              <FormControl
                variant="standard"
                fullWidth
                sx={{ marginBottom: 2 }}
              >
                <Item>
                  <Typography
                    variant="standard"
                    sx={{ fontWeight: "bold", fontStyle: "italic" }}
                  >
                    From Date
                  </Typography>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                </Item>
                <Item>
                  <Typography sx={{ fontWeight: "bold", fontStyle: "italic" }}>
                    To Date
                  </Typography>
                  <TextField
                    variant="standard"
                    fullWidth
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                </Item>
              </FormControl>
              <Item>
                {/* <Box textAlign="center"> */}
                <Button
                  variant="contained"
                  onClick={() =>
                    handleGenerateAdminReport(
                      vendorId,
                      "SalesByProduct",
                      startDate,
                      endDate
                    )
                  }
                  endIcon={<ArticleIcon />}
                >
                  Generate Report
                </Button>
                <Toaster containerStyle={{ zIndex: 999999999999999 }} />
              </Item>
            </Stack>
          </Box>
        </Container>
      )}
    </>
  );
};
export default VendorsReports;
