import { paymentTermsObject } from "../../constant/paymentTerms";

export const ORDERS_COLUMNS = [
  {
    header: "Customer Name",
    accessorKey: "CDES",
    enableSorting: true,
    enableColumnFilter: true,
    hidden: true
  },
  {
    header: "Order Num",
    accessorKey: "ORDNAME",
    enableSorting: true,
    enableColumnFilter: true,
  },
  {
    header: "Order Status",
    accessorKey: "ORDSTATUSDES",
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    header: "Details",
    accessorKey: "DETAILS",
    enableColumnFilter: false,
    enableSorting: true,
  },
  {
    header: "Payment terms",
    accessorKey: "PAYCODE",
    enableColumnFilter: false,
    enableSorting: true,
    Cell: ({ cell }) => paymentTermsObject[cell.row.original.PAYCODE],
  },
  {
    header: "Vendor name",
    accessorKey: "DEXT_SUPPDES",
    enableSorting: true,
  },
  {
    header: "Vendor comments",
    accessorKey: "DEXT_VENTORREMARKS",
    enableColumnFilter: false,
    enableSorting: true,
  },
  {
    header: "Quantity",
    accessorKey: "TOTQUANT",
    enableSorting: false,
    enableColumnFilter: false,
  },
  {
    header: "Total Amount",
    accessorKey: "TOTPRICE",
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    header: "Submission Date",
    accessorKey: "DEXT_SUBMISSIONDATE",
    enableColumnFilter: true,
    enableSorting: true,
  },
];
