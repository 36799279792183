import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useLocation } from "react-router-dom";

function OrderProductsModal(props) {
  const { popupModalProduct, show, handleClose } = props;
  const { pathname } = useLocation();
  console.log(pathname, "pathname");

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Product Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ fontSize: 14 }}>Code</th>
                <th style={{ fontSize: 14 }}>Descirption</th>
                <th style={{ fontSize: 14 }}>Price</th>
                <th style={{ fontSize: 14 }}>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {popupModalProduct.hasOwnProperty("ordersList") &&
                popupModalProduct.ordersList.length > 0 &&
                popupModalProduct.ordersList.map((productItem, index) => (
                  <tr key={index}>
                    <td style={{ fontSize: 14 }}>{productItem.PARTNAME}</td>
                    <td style={{ fontSize: 14 }}>{productItem.PDES}</td>
                    <td style={{ fontSize: 14 }}>{productItem.PRICE}</td>
                    {pathname === "/app/backorders" ? (
                      <td style={{ fontSize: 14 }}>
                        {productItem?.DEXT_FREEQTY !== 0 &&
                        productItem.TQUANT === 0
                          ? Number(productItem.TQUANT) +
                            Number(productItem?.DEXT_FREEQTY) / 1000
                          : Number(productItem.TQUANT)}
                      </td>
                    ) : (
                      <td style={{ fontSize: 14 }}>{productItem.TQUANT}</td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" style={{ color: "red" }} onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OrderProductsModal;
