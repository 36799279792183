import WishlistTable from "../components/Catalogue/WishlistTable";

function Wishlist() {
  return (
    <div>
      <h1 className="text-2xl py-4">Wishlist (Saved Template)</h1>
      <WishlistTable />
    </div>
  );
}

export default Wishlist;
