import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { SAVED_TEMPLATE_COLUMNS } from "./columns-saved-template";
import OrderProductsModal from "./ProductsModal";
import {
  deleteSavedTemplate,
  fetchSavedTemplate,
} from "./Api/savedTemplateApi";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setCartDetails } from "../../store/reducer/cart";

const WishlistTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });

  //Modal state
  const [productShow, setProductShow] = useState(false);
  const [popupModalProduct, setPopupModalProduct] = useState({});

  const handleProductClose = () => setProductShow(false);

  useEffect(() => {
    fetchSavedTemplate(sorting, columnFilters, setData, setRowCount);
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(() => SAVED_TEMPLATE_COLUMNS, []);

  const viewInCart = (data) => {
    let parsedCartList = JSON.parse(data?.cartData);
    console.log(parsedCartList, "parsedCartList");
    dispatch(setCartDetails(parsedCartList));
    if (data?.type === "cart") {
      navigate(
        `/app/catalogue?showCart=true&cartId=${data?.id}&cartName=${data?.name}`
      );
    } else {
      navigate(
        `/app/catalogue?showCart=true&templateId=${data?.id}&templateName=${data?.name}`
      );
    }
  };

  const deleteTemplate = async (id) => {
    if (window.confirm("Are you sure?")) {
      const response = await deleteSavedTemplate(id);
      if (response?.message) {
        toast.success(response?.message);
      } else {
        toast.error(response?.error);
      }
      fetchSavedTemplate(sorting, columnFilters, setData, setRowCount);
    }
  };

  return (
    <>
      <OrderProductsModal
        show={productShow}
        popupModalProduct={popupModalProduct}
        handleClose={handleProductClose}
      />
      <MaterialReactTable
        columns={columns}
        data={data}
        initialState={{ showColumnFilters: true }}
        manualFiltering
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
        enableRowActions
        renderRowActions={({ row }) => (
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <button
              className="btn btn-primary w-28"
              onClick={() => {
                viewInCart(row?.original);
              }}
              style={{
                fontSize: "15px",
              }}
            >
              View in Cart
            </button>
            <button
              className="btn btn-danger w-24"
              onClick={() => {
                deleteTemplate(row?.original?.id);
              }}
              style={{
                fontSize: "15px",
              }}
            >
              Delete
            </button>
          </div>
        )}
      />
    </>
  );
};

export default WishlistTable;
