import React, { useEffect, useMemo, useState, useCallback } from "react";
import MaterialReactTable from "material-react-table";
import { Info } from "@mui/icons-material";
import { Toaster } from "react-hot-toast";

import { COLUMNS } from "./columns-new-code";
import "./ReturnProductTable.css";
import axios from "axios";
import NewCodeItemListModal from "./NewCodeItemListModal";

const NewCodeTable = ({ isVendorName }) => {
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const userId = localStorage.getItem("userId");

  const fetchReturnProducts = async () => {
    setIsLoading(true);
    const url = new URL(
      "/get-new-code",
      `${process.env.REACT_APP_API_URL}`
    );
    if (userId !== "S9999") {
      url.searchParams.set("user_id", userId);
    }
    url.searchParams.set("page", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? [])); //[{"id":"PARTNAME","value":"sa"}]
    url.searchParams.set("globalFilter", globalFilter ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    try {
      const response = await axios.get(url);
      setData(response?.data?.data);
      setRowCount(response?.data?.total);
      console.log(response?.data, "sddfdfd");
    } catch (error) {
      console.log(error, "error fetching return product");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReturnProducts();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    globalFilter,
    columnFilters,
  ]);

  //custom state
  const [selectedRow, setSelectedRow] = useState(undefined);

  const options = {
    filtering: true,
  };

  console.log(selectedRow, "selectedRow");

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 50,
          },
        }}
        columns={COLUMNS}
        data={data}
        initialState={{
          showColumnFilters: true,
          density: window.innerWidth > 1024 ? "comfortable" : "standard",
        }}
        manualFiltering
        options={options}
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onGlobalFilterChange={setGlobalFilter}
        onColumnFiltersChange={setColumnFilters}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          showGlobalFilter: true,
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
        enableRowActions
        renderRowActions={({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Info
              className="table-info-button"
              style={{
                color: "#1f79d5",
                cursor: "pointer",
                fontSize: "35px",
              }}
              onClick={() => {
                setSelectedRow(row?.original);
              }}
            ></Info>
          </div>
        )}
      />
      <Toaster containerStyle={{ zIndex: 999999999999999 }} />
      {selectedRow && (
        <NewCodeItemListModal
          products={selectedRow?.details}
          show={true}
          handleClose={() => {
            setSelectedRow(undefined);
          }}
        />
      )}
    </>
  );
};

export default NewCodeTable;
