import React, { useEffect, useMemo, useState, useCallback } from "react";
import MaterialReactTable from "material-react-table";
import { Info } from "@mui/icons-material";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";

import { COLUMNS } from "./columns-return-product";
import "./ReturnProductTable.css";
import axios from "axios";
import ProductListModal from "./ProductListModal";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

const ReturnProductTable = ({ isVendorName }) => {
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [newInvoiceNo, setNewInvoiceNo] = useState("");

  //table state
  const [columnFilters, setColumnFilters] = useState([]);

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const userId = localStorage.getItem("userId");

  const fetchReturnProducts = async () => {
    setIsLoading(true);
    const url = new URL(
      "/get-return-products",
      `${process.env.REACT_APP_API_URL}`
    );
    if (userId !== "S9999") {
      url.searchParams.set("user_id", userId);
    }
    url.searchParams.set("page", `${pagination.pageIndex}`);
    url.searchParams.set("size", `${pagination.pageSize}`);
    url.searchParams.set("filters", JSON.stringify(columnFilters ?? [])); //[{"id":"PARTNAME","value":"sa"}]
    url.searchParams.set("globalFilter", globalFilter ?? "");
    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
    try {
      const response = await axios.get(url);
      setData(response?.data?.data);
      setRowCount(response?.data?.total);
      console.log(response?.data, "sddfdfd");
    } catch (error) {
      console.log(error, "error fetching return product");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReturnProducts();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    globalFilter,
    columnFilters,
  ]);

  //custom state
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [itemId, setItemId] = useState(undefined);

  const options = {
    filtering: true,
  };

  const handleProcess = async (status, selectedId) => {
    const url = new URL(
      "/update-return-products",
      `${process.env.REACT_APP_API_URL}`
    );

    try {
      await axios.post(url, {
        invoiceNo: newInvoiceNo,
        id: selectedId ?? itemId,
        status,
      });
      toast.success("Data Updated Successfully!");
      setIsOpenPopup(false);
      setNewInvoiceNo("");
      fetchReturnProducts();
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 50,
          },
        }}
        columns={COLUMNS}
        data={data}
        initialState={{
          showColumnFilters: true,
          density: window.innerWidth > 1024 ? "comfortable" : "standard",
        }}
        manualFiltering
        options={options}
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onGlobalFilterChange={setGlobalFilter}
        onColumnFiltersChange={setColumnFilters}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          showGlobalFilter: true,
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
        enableRowActions
        renderRowActions={({ row }) => (
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <Info
              className="table-info-button"
              style={{
                color: "#1f79d5",
                cursor: "pointer",
                fontSize: "35px",
              }}
              onClick={() => {
                setSelectedRow(row?.original);
              }}
            ></Info>
            {row.original.status === "Pending" && userId === "S9999" && (
              <Button
                color="success"
                variant="outlined"
                size="small"
                onClick={() => {
                  setIsOpenPopup(true);
                  setItemId(row.original?.id);
                }}
              >
                Process
              </Button>
            )}
            {row.original.status !== "Pending" && userId === "S9999" && (
              <Button
                color="error"
                variant="outlined"
                size="small"
                onClick={() => {
                  toast((t) => (
                    <span style={{ zIndex: 99999999999999 }}>
                      <Typography fontWeight={600}>
                        Do you want to unprocess?
                      </Typography>
                      <br></br>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Button
                              color="success"
                              onClick={() => {
                                handleProcess("Pending", row.original?.id);
                                toast.dismiss(t.id);
                              }}
                            >
                              Yes
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              color="error"
                              onClick={() => {
                                toast.dismiss(t.id);
                                setItemId(undefined);
                              }}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </span>
                  ));
                }}
              >
                Unprocess
              </Button>
            )}
          </div>
        )}
      />
      <Toaster containerStyle={{ zIndex: 999999999999999 }} />
      {selectedRow && (
        <ProductListModal
          selectedRow={selectedRow}
          products={selectedRow?.details}
          show={true}
          handleClose={() => {
            setSelectedRow(undefined);
          }}
          fetchReturnProducts={fetchReturnProducts}
        />
      )}

      <Modal
        size="md"
        show={isOpenPopup}
        onHide={() => {
          setIsOpenPopup(false);
          setNewInvoiceNo("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <TextField
              size="small"
              fullWidth
              multiline
              rows={2}
              label="Enter Invoice No."
              placeholder="Invoice No"
              value={newInvoiceNo}
              onChange={(e) => {
                if (e.target.value?.length > 55) return;
                setNewInvoiceNo(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end gap-3">
            <Button
              color="error"
              onClick={() => {
                setIsOpenPopup(false);
                setNewInvoiceNo("");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              disabled={!newInvoiceNo}
              onClick={() => {
                handleProcess("Processed");
              }}
            >
              Confirm Process
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReturnProductTable;
