export const customersObjectData = {
  'C1014': '938625',
  'C1016': '359900',
  'C1017': '504597',
  'C1018': '610505',
  'C1019': '649934',
  'C1021': '819519',
  'C1022': '171307',
  'C1025': '229887',
  'C1027': '990736',
  'C1028': '117986',
  'C1030': '500571',
  'C1035': '587828',
  'C1039': '900317',
  'C1041': '566526',
  'C1047': '343093',
  'C1051': '591668',
  'C1054': '225638',
  'C1057': '403290',
  'C1058': '678243',
  'C1059': '607551',
  'C1063': '739356',
  'C1066': '269367',
  'C1067': '199244',
  'C1068': '214070',
  'C1069': '740517',
  'C1071': '439454',
  'C1075': '121242',
  'C1081': '821202',
  'C1082': '536204',
  'C1089': '996638',
  'C1096': '800738',
  'C1099': '354365',
  'C1100': '995915',
  'C1101': '540609',
  'C1102': '995836',
  'C1105': '554679',
  'C1106': '164521',
  'C1108': '702459',
  'C1110': '367339',
  'C1114': '564317',
  'C1115': '470696',
  'C1116': '538882',
  'C1120': '658424',
  'C1121': '828487',
  'C1123': '863160',
  'C1127': '446972',
  'C1130': '938867',
  'C1132': '354409',
  'C1139': '181081',
  'C1143': '780539',
  'C1146': '837980',
  'C1149': '175811',
  'C1158': '606760',
  'C1161': '699280',
  'C1162': '290350',
  'C1164': '241617',
  'C1167': '926589',
  'C1168': '181071',
  'C1169': '115914',
  'C1171': '527531',
  'C1176': '537631',
  'C1178': '824066',
  'C1181': '608813',
  'C1184': '153710',
  'C1185': '958885',
  'C1186': '625961',
  'C1187': '922687',
  'C1188': '646389',
  'C1189': '333730',
  'C1190': '739527',
  'C1192': '368146',
  'C1194': '930503',
  'C1198': '624518',
  'C1199': '382544',
  'C1201': '539178',
  'C1202': '510648',
  'C1206': '890740',
  'C1208': '103288',
  'C1209': '419258',
  'C1219': '321179',
  'C1221': '975510',
  'C1227': '483610',
  'C1229': '197463',
  'C1235': '557559',
  'C1236': '611303',
  'C1237': '944237',
  'C1239': '544363',
  'C1242': '954373',
  'C1243': '875539',
  'C1253': '812757',
  'C1254': '161503',
  'C1256': '378494'
};
