import {
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

const NewCodeElectronicForm = () => {
  const navigate = useNavigate();
  const userFullId = localStorage.getItem("userId");
  const userDesc = localStorage.getItem("userDesc");
  const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const [submissionResponse, setSubmissionResponse] = useState(undefined);
  const [newCodeList, setNewCodeList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    partName: "",
    partDesc: "",
    barcode: "",
    vendorName: "",
    importerName: userFullId,
    productType: "",
    productCategory: "",
    brandName: "",
    pharmService: "",
    ghsPart: "",
    activeIngredient: "",
    piecesPerBox: "",
    storageConditions: "",
    liquidContent: "",
    fragile: "",
    length: "",
    width: "",
    height: "",
    weight: "",
    vat: "",
    wholesalePrice: "",
    retailPriceWithoutVAT: "",
    retailPriceWithVAT: "",
    vendorDiscount: "",
    purchaseOffers: "",
    salesOffers: "",
    mixMatchSalesOffers: "",
  });
  const [formError, setFormError] = useState({});

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/vendors`).then((response) => {
      console.log(response?.data);
      const vendorData = response?.data?.data;
      const formattedVendorList = vendorData.map((vendor, index) => ({
        ...vendor,
        id: index + 1,
        value: vendor?.SUPNAME,
        label: `${vendor?.SUPNAME} - ${vendor?.SUPDES}`,
      }));
      setVendorList(formattedVendorList);
    });
  }, []);

  const productType = [
    { id: "1", value: "MEDICINE", label: "MEDICINE" },
    { id: "2", value: "PARAPHARMACEUTICALS", label: "PARAPHARMACEUTICALS" },
  ];

  const documentRequiredCategories = [
    "MEDICINE",
    "ANTIPSYCHOTICS",
    "ANXIOLYTIC",
    "NARCOTIC DRUGS",
    "COSMETICS",
    "FOOD SUPPLEMENTS",
    "MEDICAL DEVICES/TOOLS",
    "ORTHOPEDICS",
  ];

  const productCategories = [
    {
      productType: "MEDICINE",
      id: "1",
      value: "MEDICINE",
      label: "MEDICINE",
      isDocumentRequired: true,
    },
    {
      productType: "MEDICINE",
      id: "2",
      value: "ANTIPSYCHOTICS",
      label: "ANTIPSYCHOTICS",
      isDocumentRequired: true,
    },
    {
      productType: "MEDICINE",
      id: "3",
      value: "ANXIOLYTIC",
      label: "ANXIOLYTIC",
      isDocumentRequired: true,
    },
    {
      productType: "MEDICINE",
      id: "4",
      value: "NARCOTIC DRUGS",
      label: "NARCOTIC DRUGS",
      isDocumentRequired: true,
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "5",
      value: "BABY CARE",
      label: "BABY CARE",
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "6",
      value: "BAND-AIDS",
      label: "BAND-AIDS",
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "7",
      value: "CHEMICALS",
      label: "CHEMICALS",
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "8",
      value: "COSMETICS",
      label: "COSMETICS",
      isDocumentRequired: true,
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "9",
      value: "DENTAL PRODUCTS",
      label: "DENTAL PRODUCTS",
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "10",
      value: "DIAGNOSTICS",
      label: "DIAGNOSTICS",
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "11",
      value: "FOOD SUPPLEMENTS",
      label: "FOOD SUPPLEMENTS",
      isDocumentRequired: true,
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "12",
      value: "FOOT / NAIL TREATMENT",
      label: "FOOT / NAIL TREATMENT",
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "13",
      value: "HOSPITAL SUPPLIES",
      label: "HOSPITAL SUPPLIES",
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "14",
      value: "INSECT REPELLENTS",
      label: "INSECT REPELLENTS",
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "15",
      value: "MEDICAL DEVICES/TOOLS",
      label: "MEDICAL DEVICES/TOOLS",
      isDocumentRequired: true,
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "16",
      value: "MISCELLANEOUS PRODUCTS",
      label: "MISCELLANEOUS PRODUCTS",
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "17",
      value: "OPHTHALMOLOGY",
      label: "OPHTHALMOLOGY",
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "18",
      value: "ORTHOPEDICS",
      label: "ORTHOPEDICS",
      isDocumentRequired: true,
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "19",
      value: "PERSONAL HEALTH CARE",
      label: "PERSONAL HEALTH CARE",
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "20",
      value: "PHARMACY CONSUMABLES",
      label: "PHARMACY CONSUMABLES",
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "21",
      value: "VETERINARY",
      label: "VETERINARY",
    },
    {
      productType: "PARAPHARMACEUTICALS",
      id: "22",
      value: "ΒΑΒΥ FORMULA",
      label: "ΒΑΒΥ FORMULA",
    },
  ];

  const formFields = [
    {
      key: "partName",
      label: "Κωδικός Προμηθευτή",
      type: "text",
      isRequired: true,
    },
    {
      key: "partDesc",
      label: "Περιγραφή κωδικού",
      type: "text",
      isRequired: true,
    },
    {
      key: "barcode",
      label: "Barcode συσκευασίας",
      type: "number",
      isRequired: true,
    },
    {
      key: "vendorName",
      label: "Κωδικός και ονομασία διανομέα",
      type: "select",
      options: vendorList,
      isRequired: true,
    },
    {
      key: "importerName",
      label: "Κωδικός και ονομασία Αντιπροσώπου",
      type: "text",
      isDisabled: true,
      isRequired: true,
    },
    {
      key: "productType",
      label: "Τύπος προϊόντος",
      type: "select",
      options: productType,
      isRequired: true,
    },
    {
      key: "productCategory",
      label: "Κατηγορία Προϊόντος",
      type: "select",
      options: productCategories,
      isRequired: true,
    },
    { key: "brandName", label: "Brand", type: "text", isRequired: true },
    {
      key: "pharmService",
      label: "Κωδικός Φαρμακευτικών υπηρεσιών",
      type: "text",
      isRequired: false,
    },
    {
      key: "ghsPart",
      label: "Προϊόν Γεσυ",
      type: "select",
      options: [
        { id: 1, value: "Y", label: "Y" },
        { id: 2, value: "N", label: "N" },
      ],
      isRequired: true,
    },
    {
      key: "activeIngredient",
      label: "δραστική ουσία",
      type: "text",
      isRequired: false,
    },
    {
      key: "piecesPerBox",
      label: "τεμάχια ανά κιβώτιο",
      type: "number",
      isRequired: true,
    },
    {
      key: "storageConditions",
      label: "συνθήκες φύλαξης",
      type: "select",
      options: [
        { id: 1, value: "D", label: "D" },
        { id: 2, value: "F", label: "F" },
      ],
      isRequired: true,
    },
    {
      key: "liquidContent",
      label: "Υγρό περιεχόμενο",
      type: "select",
      options: [
        { id: 1, value: "Y", label: "Y" },
        { id: 2, value: "N", label: "N" },
      ],
      isRequired: true,
    },
    {
      key: "fragile",
      label: "Εύθραυστο",
      type: "select",
      options: [
        { id: 1, value: "Y", label: "Y" },
        { id: 2, value: "N", label: "N" },
      ],
      isRequired: true,
    },
    {
      key: "length",
      label: "Μήκος σε εκατοστά",
      type: "number",
      isRequired: false,
    },
    {
      key: "width",
      label: "Πλάτος σε εκατοστά",
      type: "number",
      isRequired: false,
    },
    {
      key: "height",
      label: "Ύψος σε εκατοστά",
      type: "number",
      isRequired: false,
    },
    {
      key: "weight",
      label: "Βάρος σε γραμμάρια",
      type: "number",
      isRequired: false,
    },
    {
      key: "vat",
      label: "ΦΠΑ",
      type: "select",
      options: [
        { id: 1, value: "ZEG", label: "ZEG = 0%" },
        { id: 2, value: "THG", label: "THG = 3%" },
        { id: 3, value: "LOG", label: "LOG = 5%" },
        { id: 4, value: "REG", label: "REG = 9%" },
        { id: 5, value: "STG", label: "STG = 19%" },
      ],
      isRequired: true,
    },
    {
      key: "wholesalePrice",
      label: "Χονδρική τιμή χωρίς ΦΠΑ",
      type: "number",
      isRequired: true,
    },
    {
      key: "retailPriceWithoutVAT",
      label: "Λιανική τιμή χωρίς ΦΠΑ",
      type: "number",
      isRequired: true,
    },
    {
      key: "retailPriceWithVAT",
      label: "Λιανική τιμή με ΦΠΑ",
      type: "number",
      isRequired: true,
    },
    {
      key: "vendorDiscount",
      label: "Ποσοστό έκπτωσης προμηθευτή προς ΚΕΔΙΦΑΠ",
      type: "number",
      isRequired: true,
    },
    {
      key: "purchaseOffers",
      label: "Προσφορές Αγοράς",
      type: "text",
      isRequired: false,
    },
    {
      key: "salesOffers",
      label: "Προσφορές Πωλήσεων",
      type: "text",
      isRequired: false,
    },
    {
      key: "mixMatchSalesOffers",
      label: "Προσφορές Πωλήσεων Mix&Match",
      type: "text",
      isRequired: false,
    },
  ];
  const handleDeleteItem = (index) => {
    setNewCodeList((prev) => {
      const updatedList = [...prev];
      updatedList.splice(index, 1);
      return updatedList;
    });
  };

  const handleAddItem = (e) => {
    e.preventDefault();
    if (formData?.productType === "MEDICINE" && !formData?.pharmService) {
      setFormError((prev) => ({
        ...prev,
        pharmService: "This field is required",
      }));
      return;
    }
    if (formData?.productType === "MEDICINE" && !formData?.activeIngredient) {
      setFormError((prev) => ({
        ...prev,
        activeIngredient: "This field is required",
      }));
      return;
    }
    setNewCodeList((prev) => [...prev, formData]);
    setFormData(
      formFields.reduce(
        (acc, field) => ({ ...acc, importerName: userFullId, [field.key]: "" }),
        {}
      )
    );
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/new-code`,
        {
          user_id: userFullId,
          items: newCodeList,
        }
      );
      const isDocumentRequired = newCodeList.some((item) =>
        documentRequiredCategories.includes(item?.productCategory)
      );
      setSubmissionResponse({
        ...response?.data,
        isDocumentRequired: isDocumentRequired,
      });
      toast.success("New Code Submitted Successfully");
      setNewCodeList([]);
    } catch (error) {
      console.log(error, "New Code Submit Error");
      toast.error("Something went wrong! please try again");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userFullId && userFullId.includes("C")) {
      navigate("/app");
    }
  }, [userFullId]);

  return (
    <div className="mt-3">
      <div className="flex justify-between gap-2">
        {" "}
        <Typography variant="h4" align="center" fontSize={25} gutterBottom>
          ΗΛΕΚΤΡΟΝΙΚΟ ΕΝΤΥΠΟ ΝΕΟΥ ΚΩΔΙΚΟΥ
        </Typography>
        <Link
          to="/app/new-code-electronic-form-list"
          className="border px-3 py-2 rounded-md"
        >
          History
        </Link>
      </div>
      <div className="overflow-x-auto my-3">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              {formFields.map((field, index) => (
                <td
                  key={index}
                  className="p-3 border-gray-300 border-b bg-gray-100"
                >
                  {field.label}
                </td>
              ))}
              <td className="p-3 border-gray-300 border-b bg-gray-100">
                Actions
              </td>
            </tr>
          </thead>
          <tbody>
            {newCodeList.map((item, index) => (
              <tr key={index} className="border-b border-gray-300">
                {formFields.map((field, idx) => (
                  <td key={idx} className="p-3">
                    {item[field.key] || "N/A"}
                  </td>
                ))}
                <td className="p-3">
                  <Button color="error" onClick={() => handleDeleteItem(index)}>
                    Remove
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="my-1 flex justify-center">
        <Button
          variant="contained"
          color="success"
          onClick={handleSubmit}
          disabled={newCodeList?.length === 0 || isLoading}
        >
          {isLoading ? "SUBMITTING..." : "SUBMIT"}
        </Button>
      </div>
      <div className="mt-4 bg-slate-100 p-3">
        <Typography variant="h4" align="center" fontSize={22} gutterBottom>
          ADD ITEM FORM
        </Typography>
        <form onSubmit={handleAddItem}>
          <div className="mt-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
            {formFields.map((field, index) => {
              // Dynamically filter options for productCategory based on productType
              const dynamicOptions =
                field.key === "productCategory"
                  ? formData.productType
                    ? productCategories.filter(
                        (item) => item.productType === formData.productType
                      )
                    : []
                  : field.options;

              if (field.key === "partDesc") {
                // Custom handling for "partDesc" field
                return (
                  <TextField
                    key={index}
                    required={field?.isRequired ? true : false}
                    size="small"
                    fullWidth
                    label={field.label}
                    inputProps={{
                      maxLength: 50, // Limit to 50 characters
                    }}
                    value={formData.partDesc || ""}
                    onChange={(e) => {
                      let input = e.target.value;

                      // Ensure the 26th character is always a space if input length is >= 26
                      if (input.length >= 26 && input[25] !== " ") {
                        input =
                          input.substring(0, 25) + " " + input.substring(26);
                      }

                      // Update form data
                      setFormData({ ...formData, partDesc: input });
                    }}
                    helperText="50 chars max. The 26th character must always be a space."
                  />
                );
              }

              // Default handling for other fields
              return field.type === "select" ? (
                <FormControl key={index} size="small" fullWidth>
                  <InputLabel>{field.label}</InputLabel>
                  <Select
                    required={field?.isRequired ? true : false}
                    value={formData[field.key] || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, [field.key]: e.target.value })
                    }
                    label={field.label}
                    helperText={formError[field.key] || ""}
                  >
                    {dynamicOptions.map((option, optIndex) => (
                      <MenuItem key={optIndex} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  key={index}
                  required={field?.isRequired ? true : false}
                  size="small"
                  disabled={field?.isDisabled ? true : false}
                  fullWidth
                  label={field.label}
                  type={field.type === "number" ? "number" : "text"}
                  value={formData[field.key] || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, [field.key]: e.target.value })
                  }
                  error={Boolean(formError[field.key])}
                  helperText={formError[field.key] || ""}
                />
              );
            })}
          </div>
          <div className="flex justify-center mt-3">
            <Button variant="contained" type="submit">
              Add Item
            </Button>
          </div>
        </form>
      </div>
      <Modal size="md" show={submissionResponse}>
        <Modal.Header closeButton>
          <Modal.Title>Form Submitted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-full flex flex-col gap-3">
            <div>
              <p className="text-md font-bold">
                Refernce No: {submissionResponse?.new_code_id}
              </p>
              {submissionResponse?.isDocumentRequired && (
                <div className="flex flex-col gap-3">
                  <p>
                    Παρακαλούμε όπως αποστείλετε με email τα αναγκαία
                    παραστατικά και φωτογραφεία αναφέροντάς το reference no.
                  </p>
                  <p>
                    Απαραίτητα αποδεικτικά έγραφα για το άνοιγμα νέου κωδικού:
                  </p>
                  <p>
                    Συμπληρώματα διατροφής - Έντυπο γνωστοποίησης για κυκλοφορία
                    συμπληρωμάτων διατροφής σφαγιασμένο από Υπουργείο Υγείας.
                  </p>
                  <p>Καλλυντικά - CPNP Number</p>
                  <p>
                    Medical device - τη κοινοποίηση με email προς το αρμόδιο
                    τμήμα του Υπουργείου Υγείας.
                  </p>
                </div>
              )}
            </div>
            <div className="flex w-full justify-end">
              <Button
                color="error"
                onClick={() => {
                  setSubmissionResponse(undefined);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Toaster />
    </div>
  );
};

export default NewCodeElectronicForm;
