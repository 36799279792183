export const dextPolicyObject = {
  "3M": "Ο Προμηθευτής Δέχεται μόνο Ληξιπρόθεσμα 3Μ",
  "4M": "Ο Προμηθευτής Δέχεται μόνο Ληξιπρόθεσμα 4Μ",
  "6M": "Ο Προμηθευτής Δέχεται μόνο Ληξιπρόθεσμα 6Μ",
  "6M/EXP": "Ο Προμηθευτής Δέχεται Ληγμένα & Ληξιπρόθεσμα 6M",
  ALL: "Ο Προμηθευτής Δέχεται Όλες τις Επιστροφές",
  EXP: "Ο Προμηθευτής Δέχεται μόνο Ληγμένα",
  NONE: "Ο Προμηθευτής ΔΕΝ Δέχεται Επιστροφές",
  SPECIAL: "Ειδική Συμφωνία",
};
