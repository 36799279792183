import moment from "moment";

export const STATEMENTS_COLUMNS = [
  {
    header: "Customer Number",
    accessorKey: "CUSTNAME2",
  },
  {
    header: "From Date",
    accessorKey: "FROMDATE",
    enableColumnFilter: false,
    Cell: (props) => moment(props.renderedCellValue).format("DD-MM-YYYY"),
  },
  {
    header: "To Date",
    accessorKey: "TODATE",
    enableColumnFilter: false,
    Cell: (props) => moment(props.renderedCellValue).format("DD-MM-YYYY"),
  },
  // {
  //   header: "Customer Statement",
  //   accessorKey: "STMT_EXTFILENAME",
  //   enableColumnFilter: false,
  //   enableSorting: false,
  //   enableGlobalFilter: false,
  //   Cell: (props) => {
  //     return (
  //       <a href={props.renderedCellValue} target="_blank" style={{color: '#0d6efd'}}>
  //         PDF Link
  //       </a>
  //     );
  //   },
  // },
  {
    header: "Statement Number",
    accessorKey: "ID",
  },
  {
    header: "Statement Name",
    accessorKey: "CUSTDES",
  },
  {
    header: "Date",
    accessorKey: "TIMESTAMP",
    enableColumnFilter: false,
  },
];
