
const ProductPopup = (data, id, show, setShow, setPopupModalData) => {
    const rowSearch = data.find((result) => result.PARTNAME == id.PARTNAME);
    console.log(id, 'product details')

    setShow(!show);
    setPopupModalData({
      code: id.PARTNAME,
      description: id.PARTDES,
      stock: id.stock,
      price: id.WSPLPRICE,
      priceVat: id.VATPRICE,
      vat: id.VATPRICE,
      distributer: id.VENDOR_DES,
      barcode: id.BARCODE,
      pharmaCode: id.SPEC14,
      supplier: id.IMPORTER_DES,
      ghs: id.DEXT_GHS,
      fragile: id.DEXT_FRAGILE,
      liquid: id.DEXT_LIQUID,
      fridge: id.DEXT_FRIDGE,
      policy: id.DEXT_CSPOLICYCODE,
      stock_object: id.stock_object,
      VAT: id.VAT
    });
  };

  export default ProductPopup;
