import moment from "moment";
import FlagIcon from "@mui/icons-material/Flag";

const now = moment();

export const COLUMNS = [
  // {
  //   header: "Back To Stock",
  //   accessorKey: "BACKTOSTOCK",
  //   enableColumnFilter: false,
  //   enableSorting: false,
  //   Cell: ({ cell }) => {
  //     if (cell.row.original.BACKTOSTOCK === "1") {
  //       return <FlagIcon color="success" />
  //     } else {
  //       return <FlagIcon color="error" />
  //     }
  //   },
  // },
  {
    header: "Κωδικός",
    accessorKey: "PARTNAME",
    size: 5,
  },
  {
    header: "Περιγραφή",
    accessorKey: "PARTDES",
    Cell: ({ cell }) => (
      <p className="part-description">{cell.row.original.PARTDES}</p>
    )
  },
  {
    header: "Απόθεμα",
    accessorKey: "stock",
    enableColumnFilter: false,
    // enableSorting: false,
    size: 8
  },
  {
    header: "Expected Stock",
    accessorKey: "QTYTORECEIVE",
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    header: "Expiration",
    accessorKey: "expiry",
    enableColumnFilter: false,
    enableSorting: false,
    Cell: ({ cell }) => {
      const expiryDate = moment(cell.row.original.expiry, "DD-MM-YYYY");
      const monthsDifference = now.diff(expiryDate, "months");
      if (monthsDifference > -6) {
        return (
          <p style={{ color: "red", fontWeight: "700" }}>
            {cell.row.original.expiry}
          </p>
        );
      } else {
        return (
          <p style={{ color: "green", fontWeight: "700" }}>
            {cell.row.original.expiry}
          </p>
        );
      }
    },
  },
  {
    header: "Quota",
    accessorKey: "DEXT_LOWSTOCKQTY",
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    header: "Bar Code",
    accessorKey: "BARCODE",
  },
  {
    header: "ΧΤ",
    accessorKey: "WSPLPRICE",
    enableColumnFilter: false,
    size:10,
  },
  {
    header: "ΛΤ",
    accessorKey: "VATPRICE",
    enableColumnFilter: false,
    size: 20,
  },
  {
    header: "Brand",
    accessorKey: "DEXT_BRAND",
  },

  {
    header: "Κατηγορία",
    accessorKey: "SPEC19",
  },
  {
    header: "Pharm. Service Code",
    accessorKey: "SPEC14",
    enableColumnFilter: false,
  },
  {
    header: "Διανομέας",
    accessorKey: "VENDOR_DES",
    show: false,
    enableColumnFilter: false,
  },
  {
    header: "Αντιπρόσωπος",
    accessorKey: "IMPORTER_DES",
    enableColumnFilter: false,
  },

  {
    header: "Δραστική ουσία",
    accessorKey: "SPEC1",
  },
];
