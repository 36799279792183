import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useLocation } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import axios from "axios";
import toast from "react-hot-toast";

function ProductListModal(props) {
  const { selectedRow, products, show, handleClose, fetchReturnProducts } =
    props;

  const userId = localStorage.getItem("userId");

  const [allProducts, setAllProducts] = useState(products);
  const [comment, setComment] = useState(selectedRow?.comment || "");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAllProducts(products);
  }, [products]);

  console.log(allProducts, "allProducts");

  const handleProcess = async (id, status) => {
    const url = new URL(
      "/update-return-product-details",
      `${process.env.REACT_APP_API_URL}`
    );

    try {
      await axios.post(url, { id, status });
      toast.success("Data Updated Successfully!");
      if (status === "Processed") {
        const updatedData = allProducts.map((item) => ({
          ...item,
          status: item?.detail_id === id ? "Processed" : item?.status,
        }));
        setAllProducts(updatedData);
      } else {
        const updatedData = allProducts.map((item) => ({
          ...item,
          status: item?.detail_id === id ? "Pending" : item?.status,
        }));
        setAllProducts(updatedData);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Something went wrong!");
    }
  };

  const handleUpdateAllList = async (status) => {
    const url = new URL(
      "/update-return-product-details/all",
      `${process.env.REACT_APP_API_URL}`
    );

    try {
      setIsLoading(true);
      await axios.post(url, {
        product_return_id: allProducts[0]?.product_return_id,
        status,
      });
      toast.success("Data Updated Successfully!");
      const updatedData = allProducts.map((item) => ({
        ...item,
        status: status,
      }));
      setAllProducts(updatedData);
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitComment = async () => {
    const url = new URL(
      "/update-return-products",
      `${process.env.REACT_APP_API_URL}`
    );

    try {
      setIsLoading(true);
      await axios.post(url, {
        comment: comment,
        id: selectedRow?.id,
      });
      toast.success("Data Updated Successfully!");
      fetchReturnProducts();
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Product List ({selectedRow?.custom_id})</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="overflow-x-auto my-3">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                {[
                  "IV",
                  "CODE",
                  "Description",
                  "Return Policy",
                  "Exp. Date",
                  "Lot No.",
                  "QTY",
                  "Vendor Agreement",
                  "Status",
                ].map((header, index) => (
                  <th key={index} className="p-3 border-gray-300 text-xs">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {allProducts &&
                allProducts?.map((item, index) => (
                  <tr key={index} className="border-b border-gray-300">
                    <td className="p-3 text-xs">{item?.IV || "N/A"}</td>
                    <td className="p-3 text-xs">{item?.CODE}</td>
                    <td className="p-3 text-xs">{item?.Description}</td>
                    <td className="p-3 text-xs">{item?.Return_Policy}</td>
                    <td className="p-3 text-xs">{item?.Exp_Date || "-"}</td>
                    <td className="p-3 text-xs">{item?.Lot_No}</td>
                    <td className="p-3 text-xs">{item?.QTY}</td>
                    <td className="p-3 text-xs">
                      {item?.Special_Agreement &&
                      item?.Special_Agreement !== "N/A"
                        ? "Yes"
                        : "N/A"}
                    </td>
                    <td className="p-3 text-xs">
                      <div>
                        <div
                          className={
                            item?.status === "Pending"
                              ? "text-red-600"
                              : "text-green-600"
                          }
                        >
                          {item?.status}
                        </div>
                        {(selectedRow?.type === "AN" ||
                          selectedRow?.type === "LE" ||
                          selectedRow?.type === "KE") &&
                          userId === "S9999" && (
                            <div>
                              {item?.status === "Pending" ? (
                                <IconButton
                                  color="success"
                                  onClick={() => {
                                    handleProcess(item?.detail_id, "Processed");
                                  }}
                                >
                                  <Check />
                                </IconButton>
                              ) : (
                                <IconButton
                                  color="error"
                                  onClick={() => {
                                    handleProcess(item?.detail_id, "Pending");
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              )}
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="">
          <p className="font-medium">Comment:</p>
          {userId !== "S9999" ? (
            <div className="text-sm">
              {selectedRow?.comment || "No comment available"}
            </div>
          ) : (
            <div>
              <div>
                <textarea
                  placeholder="Write your comment"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  style={{
                    width: "50%",
                    border: "1px solid #d4d4d4",
                    marginTop: "5px",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <Button
                variant="outlined"
                onClick={handleSubmitComment}
                disabled={isLoading}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-end gap-3">
          {userId === "S9999" && (
            <Button
              variant="outlined"
              color="success"
              onClick={() => {
                handleUpdateAllList("Processed");
              }}
              disabled={isLoading}
            >
              Process All
            </Button>
          )}
          {userId === "S9999" && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                handleUpdateAllList("Pending");
              }}
              disabled={isLoading}
            >
              Unprocess All
            </Button>
          )}
          <Button color="error" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ProductListModal;
