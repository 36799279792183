import { useEffect, useState } from "react";
import OrdersTable from "../components/Catalogue/OrdersTable";
import { Link, useNavigate } from "react-router-dom";
import forwardLogo from "../assets/forward-logo.png";
import backLogo from "../assets/back-logo.png";

function Orders({ isVendorName }) {
  const navigate = useNavigate();
  const [loadingVendorName, setLoadingVendorName] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingVendorName(false);
    }, 2500);
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center gap-2 py-3 px-2 bg-white lg:static sticky top-0 left-0 z-[100] lg:shadow-none shadow-md">
        <div className="flex gap-1 items-center">
          <img
            src={backLogo}
            width={25}
            height={10}
            onClick={() => navigate(-1)}
            className="lg:hidden"
          />
          <h1 className="text-xl md:text-2xl">Παραγγελίες</h1>
          {/* <div
          className="flex  items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <p>Back</p>
          <img src={forwardLogo} width={20} height={10} />
        </div> */}
        </div>
      </div>
      {!loadingVendorName ? (
        <OrdersTable isVendorName={isVendorName} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Orders;
