import { useState, useEffect, useRef } from "react";
import { Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Swiper, SwiperSlide } from "swiper/react";
import LoginForm from "./LoginForm";
import Modal from "react-bootstrap/Modal";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/scrollbar";
import slider1 from "../assets/1.jpg";
import slider2 from "../assets/2.jpg";
import slider3 from "../assets/3.jpg";
import slider4 from "../assets/4.jpg";
import logo from "../assets/kedi logo.png";
import leafe from "../assets/leafe.png";
import "./login.css";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

export default ({
  setIsAuthenticated,
  setIsVendorName,
  setIsAdmin,
  setIsLoading,
}) => {
  const [swiperHeight, setSwiperHeight] = useState(0);

  useEffect(() => {
    function handleResize() {
      setSwiperHeight(window.innerHeight); // subtracting any additional padding/margin
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const modalRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pharmacyName, setPharmacyName] = useState("");
  const [userId, setUserId] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Clicked outside the modal
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal();
    }
  };

  const handleForgotPassword = (e) => {
    if (!pharmacyName) {
      toast.error("Pharmacy name is required");
      return;
    }
    setIsSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/request-forgot-password`, {
        pharmacyName,
        userId,
      })
      .then((res) => {
        console.log(res, "Request Sent Successfully");
        toast.success(res?.data?.message || "Request Sent Successfully");
      })
      .catch((error) => {
        toast.error(error?.message || "Request Failed");
        console.log(error, "Request Failed");
      })
      .finally(() => {
        setShowModal(false);
        setIsSubmitting(false);
      });
  };

  return (
    <div className="login-page-wrapper">
      <Container fluid>
        <Row style={{ height: "100%", overflow: "hidden" }}>
          <Col
            md={6}
            className="login-slider"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <Swiper
              // className="shadowed"
              modules={[Scrollbar, A11y, Autoplay]}
              autoplay={true}
              style={{ height: `${swiperHeight}px` }}
              scrollbar={{ draggable: true }}
            >
              <SwiperSlide>
                <img
                  className="w-full img-slider"
                  src="https://app.portal.kedifap.com/public/assets/1.jpg"
                  style={{
                    objectFit: "cover",
                    width: "700px",
                    height: `${swiperHeight}px`,
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-full img-slider"
                  src="https://app.portal.kedifap.com/public/assets/2.jpg"
                  style={{
                    objectFit: "cover",
                    width: "700px",
                    height: `${swiperHeight}px`,
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-full img-slider"
                  src="https://app.portal.kedifap.com/public/assets/3.jpg"
                  style={{
                    objectFit: "cover",
                    width: "700px",
                    height: `${swiperHeight}px`,
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-full img-slider"
                  src="https://app.portal.kedifap.com/public/assets/4.jpg"
                  style={{
                    objectFit: "cover",
                    width: "700px",
                    height: `${swiperHeight}px`,
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-full img-slider"
                  src="https://app.portal.kedifap.com/public/assets/5.jpg"
                  style={{
                    objectFit: "cover",
                    width: "700px",
                    height: `${swiperHeight}px`,
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-full img-slider"
                  src="https://app.portal.kedifap.com/public/assets/6.jpg"
                  style={{
                    objectFit: "cover",
                    width: "700px",
                    height: `${swiperHeight}px`,
                  }}
                />
              </SwiperSlide>
            </Swiper>
          </Col>
          <Col md={6} style={{ overflowY: "scroll", height: "100vh" }}>
            <div className="container form-wrapper" style={{ padding: "20px" }}>
              <div class="d-flex justify-content-end">
                <a href="/contact">Contact us</a>
              </div>
              <img className="logo-login-top" src={logo} alt="" />
              <div className="form-header">
                <h3>Καλώς ήρθατε στο Web Portal της ΚΕΔΙΦΑΠ</h3>
                <img className="form-img" src={leafe} alt="placeholder" />
              </div>

              <LoginForm
                setIsAuthenticated={setIsAuthenticated}
                setIsVendorName={setIsVendorName}
                setIsAdmin={setIsAdmin}
                setIsLoading={setIsLoading}
                handleOpenModal={handleOpenModal}
              />
              <div
                style={{
                  padding: 15,
                  textAlign: "center",
                  maxWidth: 400,
                  margin: "auto",
                }}
              >
                <div
                  style={{
                    paddingTop: 30,
                    textAlign: "center",
                    borderTop: "1px solid #d4d4d4",
                  }}
                >
                  <a
                    href="https://www.youtube.com/@KEDIFAPB2B"
                    className="outlined-button"
                  >
                    Video Tutorials
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Modal
          show={showModal}
          onClick={handleClickOutside}
          onHide={handleCloseModal}
        >
          <div ref={modalRef}>
            <Modal.Header closeButton>
              <Modal.Title>Request for forgot password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TextField
                label="Pharmacy name"
                value={pharmacyName}
                onChange={(e) => {
                  setPharmacyName(e.target.value);
                }}
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <TextField
                label="User ID"
                value={userId}
                onChange={(e) => {
                  setUserId(e.target.value);
                }}
                fullWidth
                sx={{ mb: 2 }}
              />
              <Button variant="contained" onClick={handleForgotPassword}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Modal.Body>
          </div>
        </Modal>
      </Container>
      <Toaster
        toastOptions={{
          duration: 3000,
        }}
        containerStyle={{zIndex: 999999999999999}}
      />
    </div>
  );
};
