import axios from "axios";
import { useEffect, useState } from "react";

const allList = [
  {
    name: "sample client",
    adsImg: "/public/adsImage/ads1.png",
    url: "https://abc.com",
  },
  {
    name: "sample client",
    adsImg: "/public/adsImage/ads1.png",
    url: "https://abc.com",
  },
  {
    name: "sample client",
    adsImg: "/public/adsImage/ads1.png",
    url: "https://abc.com",
  },
  {
    name: "sample client",
    adsImg: "/public/adsImage/ads1.png",
    url: "https://abc.com",
  },
  {
    name: "sample client",
    adsImg: "/public/adsImage/ads1.png",
    url: "https://abc.com",
  },
  {
    name: "sample client",
    adsImg: "/public/adsImage/ads1.png",
    url: "https://abc.com",
  },
  {
    name: "sample client",
    adsImg: "/public/adsImage/ads1.png",
    url: "https://abc.com",
  },
  {
    name: "sample client",
    adsImg: "/public/adsImage/ads1.png",
    url: "https://abc.com",
  },
  {
    name: "sample client",
    adsImg: "/public/adsImage/ads1.png",
    url: "https://abc.com",
  },
  {
    name: "sample client",
    adsImg: "/public/adsImage/ads1.png",
    url: "https://abc.com",
  },
];

function Banners() {
  const pathname = window.location?.pathname
  const [bannerList, setBannerList] = useState([]);

  async function getRandomItems(arr, numItems) {
    const shuffled = arr.slice().sort(() => 0.5 - Math.random());
    return shuffled.slice(0, numItems);
  }

  const fetchBannerList = async () => {
    axios
      .get("https://app.portal.kedifap.com/public/ads.json")
      .then(async (response) => {
        if (response?.data?.length > 3) {
          const randomItems = await getRandomItems(response?.data, 3);
          setBannerList(randomItems);
        } else {
          setBannerList(response?.data);
        }
      })
      .catch(async (error) => {
        console.log(error, "fetch Ads error");
        const randomItems = await getRandomItems(allList, 3);
        setBannerList(randomItems);
      });
  };

  useEffect(() => {
    fetchBannerList();
  }, [pathname]);

  return (
    <div className="flex items-center justify-start gap-3 py-10 banner-slider flex-wrap">
      {bannerList.map((item, index) => (
        <a href={item?.url} key={index}>
          <img
            src={item?.adsImg}
            alt={item?.name}
            style={{ width: "100%", maxWidth: 350, height: 90 }}
          />
        </a>
      ))}
    </div>
  );
}

export default Banners;
