import cartSession from "../Cart-components/cartSession";

function caluclateDiscount(productsInCart) {
  let totalDiscount = 0;
  let freeItems = [];
  let discountLabel = [];
  productsInCart.forEach((singleCartItem) => {
    let applicableDiscount = { OFFERQTY: -1 };
    //check if product has discount property
    console.log("ran disc discount 1", singleCartItem);

    if (singleCartItem.discounts) {
      singleCartItem.discounts.forEach((discount) => {
        //check if product quantity more than discount quantity
        console.log(discount, "discount dev 3");
        console.log(applicableDiscount, "applicableDiscount.quantity dev 3");
        if (
          parseInt(singleCartItem.quantity) >= parseInt(discount.OFFERQTY) &&
          parseInt(applicableDiscount.OFFERQTY) < parseInt(discount.OFFERQTY)
        ) {
          applicableDiscount = discount;
        }
      });
      //caluclating discount
      if (applicableDiscount !== null && Number(applicableDiscount.OFFERQTY) !== -1) {
        if (Number(applicableDiscount.FREEQTY) > 0) {
          freeItems = {
            ...freeItems,
            [singleCartItem.PARTNAME]: applicableDiscount.FREEQTY,
          };
        } else {
          let addedDiscountedUnit =
            ((singleCartItem.WSPLPRICE * applicableDiscount.DISCOUNT) / 100) *
            parseInt(singleCartItem.quantity);
          //if there's applicable discount apply it
          console.log(applicableDiscount, "applicableDiscount 1x");
          totalDiscount += addedDiscountedUnit;
        }
        discountLabel = {
          ...discountLabel,
          [singleCartItem.PARTNAME]: applicableDiscount.OFFERDES,
        };
        console.log(applicableDiscount, "applicableDiscount 2x");
      }

      // REMOVE discount when wuantity will be less than offer qty
      console.log(Number(singleCartItem?.quantity), Number(applicableDiscount?.OFFERQTY), 'applicableDiscount ')
      if(Number(applicableDiscount?.OFFERQTY < 1)){
        totalDiscount -= 0
        localStorage.setItem("kediCartDiscount", totalDiscount);
        // freeItems = {
        //   ...freeItems,
        //   [singleCartItem.PARTNAME]: 0,
        // };
        // cartSession.setSessionFreeQuantity(singleCartItem.PARTNAME, "0")
      }else{
        const discountAmount = Number(singleCartItem?.WSPLPRICE) * Number(applicableDiscount?.FREEQTY)
        totalDiscount += discountAmount
        localStorage.setItem("kediCartDiscount", totalDiscount);
      }
      if(applicableDiscount?.OFFERQTY > 0){
        cartSession.setSessionFreeQuantity(singleCartItem.PARTNAME, applicableDiscount?.FREEQTY)
      }
    }
  });
  const currentCartDiscount = localStorage.getItem("kediCartDiscount");

  return {
    totalDiscount: !totalDiscount
      ? Number(currentCartDiscount || 0).toFixed(2)
      : Number(totalDiscount || 0).toFixed(2),
    discountLabel: discountLabel,
    freeItems: freeItems,
  };
}

export default caluclateDiscount;
