export const paymentTermsObject = {
    "00": "Net off Today",
    "01": "C.O.D.",
    "02": "Prepaid",
    "03": "Net 10 Days",
    "04": "Net 15 Days",
    "05": "Net 20 Days",
    "06": "Net 30 Days",
    "07": "1% 10, Net 30 Days",
    "08": "2% 10, Net 30 Days",
    "09": "Net 45 Days",
    "12": "Net Month End",
    "120": "120 Days",
    "13": "EOM + 15 Days",
    "14": "Net 30th Next Month",
    "15": "EOM + 1,5 Months",
    "150": "150 Days",
    "16": "EOM + 2 Months",
    "17": "EOM + 3 Months",
    "18": "EOM + 4 Months",
    "180": "180 Days",
    "19": "1/3 - 2/3 10th Month",
    "20": "1/3 - 2/3 25th Month",
    "200": "EOM + 1 Month",
    "201": "EOM + 2,5 Months",
    "202": "EOM + 3,5 Months",
    "203": "EOM + 4,5 Months",
    "204": "EOM + 5 Months",
    "205": "EOM + 2M + 25D",
    "21": "1/3 - 1/3 - 1/3 25 Month",
    "22": "1 Month",
    "23": "15 Days",
    "24": "40 Days",
    "25": "1 Month & 15 Days",
    "26": "2 Months",
    "27": "2 Months & 15 Days",
    "28": "3 Months",
    "29": "3 Months & 15 Days",
    "30": "4 Months",
    "300": "EOM + 25D",
    "31": "4 Months & 15 Days",
    "32": "5 Months",
    "33": "6 Months",
    "34": "7 Months",
    "40": "EOM + 1M + 25D",
    "41": "EOM + 25D",
    "605": "60 Days (Disab.30/06/21)",
    "90": "90 Days",
    "91": "EOM + 40D"
  };
  