import moment from "moment";
import FlagIcon from "@mui/icons-material/Flag";

const now = moment();

export const COLUMNS = [
  {
    header: "ID",
    accessorKey: "reference_id",
    enableColumnFilter: false,
    size: 3
  },
  {
    header: "Date",
    accessorKey: "created_at",
    enableColumnFilter: false,
    size: 5,
  },
  {
    header: "Total Items",
    accessorKey: "total_items",
    enableColumnFilter: false,
  },
  {
    header: "User ID",
    accessorKey: "user_id",
    size: 5,
  },
  {
    header: "Pharmacy Name",
    accessorKey: "firm",
    size: 8,
  },
];
