import axios from "axios";

export const FetchPharmacies = async (userId) => {
  const url = new URL(
    `${process.env.REACT_APP_API_URL}/pharmacies?SUPNAME=${userId}`
  );
  console.log(url, "url pharmacies");

  try {
    const response = await axios.get(url);
    console.log(response, "response pharmacies");
    // return response;
    const modifiedPharmaciesList = response.data.reduce(
      (acc, { FIRM, DEXT_USERLOGIN, NAME, SUPNAME, PHONE, PHONENUM }) => {
        // const existingItem = acc.find(item => item.Code === CUSTNAME);
        // if (!existingItem) {
        acc.push({ label: FIRM, Code: DEXT_USERLOGIN, name: NAME, SUPNAME, PHONE, PHONENUM });
        // }
        return acc;
      },
      []
    );

    // const filteredData = modifiedPharmaciesList.filter((customer) =>
    //   customer?.SUPNAME && customer?.SUPNAME.includes(userId)
    // );

    return modifiedPharmaciesList;
  } catch (error) {
    console.error(error);
  }
};

export const FetchB2B_PHCUSTONE = async () => {
  let userFullId = localStorage.getItem("userId");
  const url = new URL(
    `${process.env.REACT_APP_API_URL}/phonecust?DEXT_USERLOGIN=${userFullId}`
  );

  try {
    const response = await axios.get(url);
    console.log(response, "response pharmacies");
    // return response;
    const modifiedPharmaciesList = response.data.reduce(
      (acc, { FIRM, DEXT_USERLOGIN, NAME, SUPNAME, PHONE, PHONENUM }) => {
        // const existingItem = acc.find(item => item.Code === CUSTNAME);
        // if (!existingItem) {
        acc.push({ label: FIRM, Code: DEXT_USERLOGIN, name: NAME, SUPNAME, PHONE, PHONENUM });
        // }
        return acc;
      },
      []
    );

    return modifiedPharmaciesList;
  } catch (error) {
    console.error(error);
  }
};
