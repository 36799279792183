function caluclateFlatTotal(allCartItems) {
  let flatTotal = 0;
  allCartItems.forEach((singleCartItem) => {
    flatTotal +=
      parseInt(singleCartItem?.quantity || 1) * parseFloat(singleCartItem.WSPLPRICE);
  });
  
  return flatTotal;
}

export default caluclateFlatTotal;