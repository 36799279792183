import ContactInfo from "../components/Contact/ContactInfo";
import { useLoadScript } from "@react-google-maps/api";
import Map from "../components/Contact/Map";
import { Link } from "react-router-dom";

function Contact() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "",
  });
  let userId = localStorage.getItem("userId");
  return (
    <div className="shadow-md rounded-md contact-us m-2">
      {!userId && (
        <Link className="p-2 text-kedifapgreen-300" to="/app">
          Back to Login
        </Link>
      )}
      <h1 className="text-2xl font-semibold p-3 m-2 bg-kedifapgreen-300 rounded-md ">
        Στοιχεία Επικοινωνίας
      </h1>
      <div className="flex flex-wrap items-top gap-3 py-3 px-3 md:px-4">
        <ContactInfo />
        {!isLoaded ? <div>Loading ...</div> : <Map />}
      </div>
    </div>
  );
}

export default Contact;
