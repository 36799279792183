import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import pill from "../assets/login img side.png";

function LoginForm({
  setIsAuthenticated,
  setIsAdmin,
  setIsVendorName,
  setIsLoading,
  handleOpenModal,
}) {
  let navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!username || !password) {
      alert("Please enter username and password");
      return;
    }
    setIsLoading(true);

    try {
      const loginCredentials = {
        username: username,
        password: password,
      };

      const url = `${process.env.REACT_APP_API_URL}/authenticate`; //not correct
      //const url = "http://localhost:8000/authenticate";
      const response = await axios.post(url, loginCredentials);
      const token = response.data.token;
      let userId =
        response.data?.userRole === "Admin"
          ? response.data?.user
          : response.data?.user?.DEXT_USERLOGIN;
      const userDesc = response.data.user?.FIRM;
      const vendorId = response.data.user?.SUPNAME;
      const userDetails = {
        ...response.data.user,
        DEXT_CANOFFER: response.data?.DEXT_CANOFFER,
        DEXT_PAYWORD: response.data?.DEXT_PAYWORD,
        loginTime: new Date()
      };
      const oldUser = localStorage.getItem("userDetails");
      if (oldUser === JSON.stringify(userDetails)) {
        localStorage.clear();
      }
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      console.log(response.data.userDetails);
      // userId == '' ? userId = vendorId : userId = userId;
      if (response.data?.userRole === "Admin") {
        setIsAdmin(true);
      }

      if (userId == "" || userId == null) userId = vendorId;
      if (rememberMe) {
        localStorage.setItem("kedTokAuth", token);
      } else {
        sessionStorage.setItem("kedTokAuth", token);
      }
      localStorage.setItem("userId", userId);
      localStorage.setItem("userDesc", userDesc);

      setIsAuthenticated(true);
      // window.location.reload();
      return navigate({
        pathname: "/app",
        //search: `?userId=${username}`,
      });
    } catch (error) {
      setIsAuthenticated(false);
      alert("Username or Password is wrong!");
      console.error(error);
    } finally {
      setTimeout(setIsLoading(false), 2000);
    }
    //return window.location.href = "http://localhost:3000/app";
    //return (window.location.href = "https://kedi-app.com2go.co/app");
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: 400, margin: "auto" }}>
      <div className="form-group">
        <label htmlFor="username">Username</label>
        <input
          placeholder="Όνομα Χρήστη"
          type="text"
          className="form-control"
          id="username"
          value={username}
          onChange={handleUsernameChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          placeholder="Κωδικός"
          type="password"
          className="form-control"
          id="password"
          value={password}
          onChange={handlePasswordChange}
        />
      </div>

      <div
        className="form-group"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          className="d-flex justify-content-start"
          style={{ display: "flex", alignItems: "center" }}
        >
          <input
            type="checkbox"
            name=""
            id="remember-me"
            style={{ width: 17, height: 17 }}
            checked={rememberMe}
            onChange={(e) => {
              setRememberMe(e.target.checked);
            }}
          />
          <label htmlFor="remember-me" style={{ paddingLeft: "5px" }}>
            Remember me
          </label>
        </div>
        <p
          onClick={handleOpenModal}
          style={{ cursor: "pointer", color: "#82b78d" }}
        >
          Forgot Password?
        </p>
      </div>

      <div
        className="form-group"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          style={{
            background: "#82b78d",
            marginTop: "15px",
            textAlign: "center",
            width: "9em",
            border: "0px",
            color: "#000",
          }}
          type="submit"
          className="btn btn-primary form-button"
        >
          Είσοδος
        </button>
        {/* <img
          alt=""
          src={pill}
          style={{
            right: "30px",
            width: "6em",
            position: "relative",
            top: "10px",
          }}
        /> */}
      </div>
    </form>
  );
}

export default LoginForm;
