export const SAVED_TEMPLATE_COLUMNS = [
    {
        header: 'Template',
        accessorKey: 'name',
        enableSorting: true,
        enableColumnFilter: false,
    },
    {
        header: "Date",
        accessorKey: 'created_at',
        enableColumnFilter: false,
        enableSorting: true,
    },
    {
        header: "Total Amount",
        accessorKey: 'amount',
        enableColumnFilter: false
    },
    {
        header: "Total Items",
        accessorKey: 'quantity',
        enableColumnFilter: false

    },
    {
        header: "Discount",
        accessorKey: 'discount',
        enableColumnFilter: false

    },
];