import { useNavigate } from "react-router-dom";
import MaterialTable from "../components/Catalogue/MaterialTable";
import backLogo from "../assets/back-logo.png";
import { useEffect, useState } from "react";
import { LoaderIcon } from "react-hot-toast";

function ProductsTable({ isVendorName }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1800); // 2 seconds delay

    return () => clearTimeout(timer); // Cleanup timer
  }, []);
  return (
    <div>
      <div className="flex gap-1 items-center justify-between py-3 lg:py-10 px-2 bg-white lg:static sticky top-0 left-0 z-[100] lg:shadow-none shadow-md">
        {/* <img
          src={backLogo}
          width={25}
          height={10}
          onClick={() => navigate(-1)}
          className="lg:hidden"
        /> */}

        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <img
            src={backLogo}
            width={25}
            height={10}
            onClick={() => navigate(-1)}
            className="lg:hidden"
          />
          <div>
            <h1 className="text-xl md:text-2xl">Κατάλογος</h1>
            <p>Ο κατάλογος ανανεώνεται κάθε 30 λεπτά</p>
          </div>
        </div>
        {/* <button className="basket-mobile md:hidden" onClick={handleCartClick}>
          <span className="icon-mobile">
            {totalItems ? JSON.parse(totalItems).length : 0}
          </span>
        </button> */}
      </div>
      {isLoading ? (
        <div className="flex justify-center py-10 w-full"><LoaderIcon /></div>
      ) : (
        <MaterialTable isVendorName={isVendorName} />
      )}
    </div>
  );
}

export default ProductsTable;
