import { Button } from "@mui/material";
import BackordersTable from "../components/Catalogue/BackordersTable";
import axios from "axios";
import { useState } from "react";
import ExcelJS from "exceljs";
import toast from "react-hot-toast";

function BackOrders({ isVendorName }) {
  const [isLoading, setIsLoading] = useState(false);
  let userFullId = localStorage.getItem("userId");
  let [userId, dCode] = userFullId.split("-");

  const handleDownloadReports = async () => {
    try {
      setIsLoading(true);

      // Fetch data from the API
      const apiResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/backorders-reports?vendor_id=${userId}`
      );

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Backorders Report");

      // Define the headers for the Excel file
      const headerRow = [
        "ORDNAME",
        "DEXT_SUBMISSIONDATE",
        "PARTNAME",
        "PDES",
        "TQUANT",
      ];
      const headerRowStyle = worksheet.addRow(headerRow); // Add header row to the worksheet

      // Apply alignment and styles to the header row
      headerRowStyle.eachCell((cell, colNumber) => {
        cell.font = { bold: true }; // Make headers bold
        if (
          headerRow[colNumber - 1] === "TQUANT" ||
          headerRow[colNumber - 1] === "PRICE"
        ) {
          cell.alignment = { horizontal: "right" }; // Align numeric headers to the right
        } else {
          cell.alignment = { horizontal: "center" }; // Center align other headers
        }
      });

      // Iterate over the API data and filter keys based on the headerRow
      apiResponse?.data?.forEach((record) => {
        const rowValues = headerRow.map((key) => {
          const value = record[key]; // Only pick the value if the key exists in the record
          if (key === "TQUANT" || key === "PRICE") {
            return value ? parseFloat(value) : 0; // Parse numbers for specific keys
          }
          return value ?? ""; // Return empty string for missing keys
        });

        const dataRow = worksheet.addRow(rowValues); // Add the filtered values as a new row

        // Align numeric columns to the right
        dataRow.eachCell((cell, colNumber) => {
          if (
            headerRow[colNumber - 1] === "TQUANT" ||
            headerRow[colNumber - 1] === "PRICE"
          ) {
            cell.alignment = { horizontal: "right" }; // Right align for numeric columns
          }
        });
      });

      console.log("API response:", apiResponse);

      // Generate Excel file as a Blob
      const blob = await workbook.xlsx.writeBuffer();

      // Create a download link and trigger the file download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(new Blob([blob]));
      link.download = `backorders-reports.xlsx`;
      link.click();
      toast.success("Backorder Report downloaded successfully");
    } catch (error) {
      console.error("Backorders reports API error:", error);
      toast.error("Something went wrong! please try again");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between gap-5">
        <h1 className="text-2xl py-4">Back orders</h1>
        <Button
          variant="outlined"
          onClick={handleDownloadReports}
          disabled={isLoading}
          size="small"
        >
          {isLoading ? "Downloading..." : "Download Reports"}
        </Button>
      </div>
      <BackordersTable isVendorName={isVendorName} />
    </div>
  );
}

export default BackOrders;
