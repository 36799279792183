export const BACKORDERS_COLUMNS = [
    {
        header: 'Order Num',
        accessorKey: 'ORDNAME',
        enableSorting: true,
    },
    {
        header: "Submission Date",
        accessorKey: 'DEXT_SUBMISSIONDATE',
        enableColumnFilter: false,
        enableSorting: true,
    },
    {
        header: "Total Amount",
        accessorKey: 'TOTPRICE',
        enableColumnFilter: false
    },
    {
        header: "Quantity",
        accessorKey: 'TOTQUANT',
        enableColumnFilter: false,
        Cell: ({ cell }) => Number(cell.row.original?.TOTQUANT) + Number(cell.row.original?.FREEQTY),
    },
];