import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { useLocation } from "react-router-dom";

function NewCodeItemListModal(props) {
  const { products, show, handleClose } = props;

  const formFields = [
    {
      key: "partName",
      label: "Κωδικός Προμηθευτή",
      type: "text",
      isRequired: true,
    },
    {
      key: "partDesc",
      label: "Περιγραφή κωδικού",
      type: "text",
      isRequired: true,
    },
    {
      key: "barcode",
      label: "Barcode συσκευασίας",
      type: "number",
      isRequired: true,
    },
    {
      key: "vendorName",
      label: "Κωδικός και ονομασία διανομέα",
      type: "select",
      options: [],
      isRequired: true,
    },
    {
      key: "importerName",
      label: "Κωδικός και ονομασία Αντιπροσώπου",
      type: "text",
      isDisabled: true,
      isRequired: true,
    },
    {
      key: "productType",
      label: "Τύπος προϊόντος",
      type: "select",
      options: [],
      isRequired: true,
    },
    {
      key: "productCategory",
      label: "Κατηγορία Προϊόντος",
      type: "select",
      options: [],
      isRequired: true,
    },
    { key: "brandName", label: "Brand", type: "text", isRequired: true },
    {
      key: "pharmService",
      label: "Κωδικός Φαρμακευτικών υπηρεσιών",
      type: "text",
      isRequired: false,
    },
    {
      key: "ghsPart",
      label: "Προϊόν Γεσυ",
      type: "select",
      options: [
        { id: 1, value: "Y", label: "Y" },
        { id: 2, value: "N", label: "N" },
      ],
      isRequired: true,
    },
    {
      key: "activeIngredient",
      label: "δραστική ουσία",
      type: "text",
      isRequired: false,
    },
    {
      key: "piecesPerBox",
      label: "τεμάχια ανά κιβώτιο",
      type: "number",
      isRequired: true,
    },
    {
      key: "storageConditions",
      label: "συνθήκες φύλαξης",
      type: "select",
      options: [
        { id: 1, value: "D", label: "D" },
        { id: 2, value: "F", label: "F" },
      ],
      isRequired: true,
    },
    {
      key: "liquidContent",
      label: "Υγρό περιεχόμενο",
      type: "select",
      options: [
        { id: 1, value: "Y", label: "Y" },
        { id: 2, value: "N", label: "N" },
      ],
      isRequired: true,
    },
    {
      key: "fragile",
      label: "Εύθραυστο",
      type: "select",
      options: [
        { id: 1, value: "Y", label: "Y" },
        { id: 2, value: "N", label: "N" },
      ],
      isRequired: true,
    },
    {
      key: "length",
      label: "Μήκος σε εκατοστά",
      type: "number",
      isRequired: false,
    },
    {
      key: "width",
      label: "Πλάτος σε εκατοστά",
      type: "number",
      isRequired: false,
    },
    {
      key: "height",
      label: "Ύψος σε εκατοστά",
      type: "number",
      isRequired: false,
    },
    {
      key: "weight",
      label: "Βάρος σε γραμμάρια",
      type: "number",
      isRequired: false,
    },
    {
      key: "vat",
      label: "ΦΠΑ",
      type: "select",
      options: [
        { id: 1, value: "ZEG", label: "ZEG = 0%" },
        { id: 2, value: "THG", label: "THG = 3%" },
        { id: 3, value: "LOG", label: "LOG = 5%" },
        { id: 4, value: "REG", label: "REG = 9%" },
        { id: 5, value: "STG", label: "STG = 19%" },
      ],
      isRequired: true,
    },
    {
      key: "wholesalePrice",
      label: "Χονδρική τιμή χωρίς ΦΠΑ",
      type: "number",
      isRequired: true,
    },
    {
      key: "retailPriceWithoutVAT",
      label: "Λιανική τιμή χωρίς ΦΠΑ",
      type: "number",
      isRequired: true,
    },
    {
      key: "retailPriceWithVAT",
      label: "Λιανική τιμή με ΦΠΑ",
      type: "number",
      isRequired: true,
    },
    {
      key: "vendorDiscount",
      label: "Ποσοστό έκπτωσης προμηθευτή προς ΚΕΔΙΦΑΠ",
      type: "number",
      isRequired: true,
    },
    {
      key: "purchaseOffers",
      label: "Προσφορές Αγοράς",
      type: "text",
      isRequired: false,
    },
    {
      key: "salesOffers",
      label: "Προσφορές Πωλήσεων",
      type: "text",
      isRequired: false,
    },
    {
      key: "mixMatchSalesOffers",
      label: "Προσφορές Πωλήσεων Mix&Match",
      type: "text",
      isRequired: false,
    },
  ];

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Item List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="overflow-x-auto my-3">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                {formFields.map((field, index) => (
                  <th
                    key={index}
                    className="p-3 border-gray-300 border-b bg-gray-100"
                  >
                    {field.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {products.map((item, index) => (
                <tr key={index} className="border-b border-gray-300">
                  {formFields.map((field, idx) => (
                    <td key={idx} className="p-3">
                      {item[field.key] || "N/A"}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" style={{ color: "red" }} onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewCodeItemListModal;
