import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { BsExclamationTriangle } from "react-icons/bs";
import { MdWaterDrop } from "react-icons/md";
import { TbFridge } from "react-icons/tb";
import moment from "moment";
import { TextField } from "@mui/material";

function TemplateSaveModal(props) {
  const { show, handleClose, handleSubmit, templateName, setTemplateName, isSaving } =
    props;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Save Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextField
          label="Template Name"
          fullWidth
          value={templateName}
          onChange={(e) => {
            setTemplateName(e.target.value);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" className="bg-success" onClick={handleSubmit}>
          {isSaving ? "Loading..." : "Submit"}
        </Button>
        <Button variant="danger" style={{ color: "red" }} onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TemplateSaveModal;
