export const customersArrayData = [
  'C1014', 'C1016', 'C1017', 'C1018', 'C1019',
  'C1021', 'C1022', 'C1025', 'C1027', 'C1028',
  'C1030', 'C1035', 'C1039', 'C1041', 'C1047',
  'C1051', 'C1054', 'C1057', 'C1058', 'C1059',
  'C1063', 'C1066', 'C1067', 'C1068', 'C1069',
  'C1071', 'C1075', 'C1081', 'C1082', 'C1089',
  'C1096', 'C1099', 'C1100', 'C1101', 'C1102',
  'C1105', 'C1106', 'C1108', 'C1110', 'C1114',
  'C1115', 'C1116', 'C1120', 'C1121', 'C1123',
  'C1127', 'C1130', 'C1132', 'C1139', 'C1143',
  'C1146', 'C1149', 'C1158', 'C1161', 'C1162',
  'C1164', 'C1167', 'C1168', 'C1169', 'C1171',
  'C1176', 'C1178', 'C1181', 'C1184', 'C1185',
  'C1186', 'C1187', 'C1188', 'C1189', 'C1190',
  'C1192', 'C1194', 'C1198', 'C1199', 'C1201',
  'C1202', 'C1206', 'C1208', 'C1209', 'C1219',
  'C1221', 'C1227', 'C1229', 'C1235', 'C1236',
  'C1237', 'C1239', 'C1242', 'C1243', 'C1253',
  'C1254', 'C1256'
];
