import React from "react";
import { Container, Typography, Box } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy for Kedifap
        </Typography>

        <Typography variant="body1" paragraph>
          Effective Date: [Current Date]
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Kedifap (https://app.portal.kedifap.com/app). Kedifap
          ("we," "us," or "our") is committed to protecting your privacy. This
          Privacy Policy explains how we collect, use, disclose, and safeguard
          your information when you visit our website. Please read this Privacy
          Policy carefully. If you do not agree with the terms of this Privacy
          Policy, please do not access the site.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We may collect information about you in a variety of ways. The
          information we may collect on the site includes:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Personal Data:</strong> Personally identifiable information,
          such as your name, shipping address, email address, and telephone
          number, that you voluntarily give to us when you register with the
          site or when you choose to participate in various activities related
          to the site, such as online chat and message boards.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Derivative Data:</strong> Information our servers
          automatically collect when you access the site, such as your IP
          address, your browser type, your operating system, your access times,
          and the pages you have viewed directly before and after accessing the
          site.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Financial Data:</strong> Financial information, such as data
          related to your payment method (e.g., valid credit card number, card
          brand, expiration date) that we may collect when you purchase, order,
          return, exchange, or request information about our services from the
          site.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Data from Social Networks:</strong> User information from
          social networking sites, such as Facebook, Google+, Instagram,
          including your name, your social network username, location, gender,
          birth date, email address, profile picture, and public data for
          contacts, if you connect your account to such social networks.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          3. Use of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may use information collected about you via the site to:
        </Typography>
        <Typography variant="body1" paragraph>
          - Create and manage your account.
          <br />
          - Process your transactions.
          <br />
          - Email you regarding your account or order.
          <br />
          - Fulfill and manage purchases, orders, payments, and other
          transactions related to the site.
          <br />
          - Generate a personal profile about you to make future visits to the
          site more personalized.
          <br />
          - Increase the efficiency and operation of the site.
          <br />
          - Monitor and analyze usage and trends to improve your experience with
          the site.
          <br />
          - Offer new products, services, and/or recommendations to you.
          <br />- Perform other business activities as needed.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          4. Disclosure of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>By Law or to Protect Rights:</strong> If we believe the
          release of information about you is necessary to respond to legal
          process, to investigate or remedy potential violations of our
          policies, or to protect the rights, property, and safety of others, we
          may share your information as permitted or required by any applicable
          law, rule, or regulation.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Business Transfers:</strong> We may share or transfer your
          information in connection with, or during negotiations of, any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Third-Party Service Providers:</strong> We may share your
          information with third parties that perform services for us or on our
          behalf, including payment processing, data analysis, email delivery,
          hosting services, customer service, and marketing assistance.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          5. Security of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          6. Policy for Children
        </Typography>
        <Typography variant="body1" paragraph>
          We do not knowingly solicit information from or market to children
          under the age of 13. If we learn that we have collected personal
          information from a child under age 13 without verification of parental
          consent, we will delete that information as quickly as possible. If
          you believe we might have any information from or about a child under
          13, please contact us at customer-service@kedifap.com.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          7. Controls for Do-Not-Track Features
        </Typography>
        <Typography variant="body1" paragraph>
          Most web browsers and some mobile operating systems include a
          Do-Not-Track ("DNT") feature or setting you can activate to signal
          your privacy preference not to have data about your online browsing
          activities monitored and collected. No uniform technology standard for
          recognizing and implementing DNT signals has been finalized. As such,
          we do not currently respond to DNT browser signals or any other
          mechanism that automatically communicates your choice not to be
          tracked online.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          8. Options Regarding Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          You may at any time review or change the information in your account
          or terminate your account by:
        </Typography>
        <Typography variant="body1" paragraph>
          - Logging into your account settings and updating your account.
          <br />- Contacting us using the contact information provided below.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have questions or comments about this Privacy Policy, please
          contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          Email: customer-service@kedifap.com
          <br />
          Phone: +30 26 819 595
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
