import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

// Register the different font weights
Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Regular.ttf",
  fontWeight: "normal",
});
Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Medium.ttf",
  fontWeight: "bold",
});

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
    fontFamily: "Roboto",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  headerText: {
    fontSize: 12,
    fontWeight: "bold",
  },
  headerTextLarge: {
    fontSize: 16,
    fontWeight: "bold",
  },
  sectionTitle: {
    marginVertical: 8,
    fontSize: 11,
    fontWeight: "bold",
  },
  table: {
    width: "100%",
    marginVertical: 10,
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHead: {
    padding: 4,
    border: "1px solid #000",
    // width: "14.2857%", // for 7 columns
    textAlign: "center",
    fontSize: 9,
  },
  tableCell: {
    padding: 4,
    border: "1px solid #000",
    // width: "14.2857%", // for 7 columns
    textAlign: "center",
    fontSize: 8,
  },
  tableHeader: {
    backgroundColor: "#eeeeee",
    fontWeight: "bold",
  },
  signatureBlock: {
    marginTop: 22,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  signatureLine: {
    width: "40%",
    borderTop: "1px solid #000",
    textAlign: "center",
    marginTop: 10,
  },
});

// Create Document Component
export const ReturnProductPdf = ({ allReturnProducts, otherInfo }) => {
  const isAgreement = allReturnProducts.find((item) => item?.agreement);

  const expiredItems = allReturnProducts.filter((item) => item?.isExpired);
  const withoutExpiredItems = allReturnProducts.filter(
    (item) => !item?.isExpired
  );
  return (
    <Document>
      {(otherInfo?.currentTab !== "overDue" ||
        withoutExpiredItems?.length > 0) && (
        <Page size="A4" style={styles.page}>
          {/* Header Section */}
          <View style={styles.header}>
            <Text style={styles.headerTextLarge}>Return form</Text>
          </View>
          {/* Customer Info Section */}
          <View style={styles.header}>
            <Text style={styles.headerText}>
              Customer code: {otherInfo.customer.userFullId}
            </Text>
            <Text style={styles.headerText}>
              Serial No.: {otherInfo?.type}
              {otherInfo.serialNo}
            </Text>
          </View>
          <View style={styles.header}>
            <Text style={styles.headerText}>
              Customer Name: {otherInfo.customer.name}
            </Text>
            <Text style={styles.headerText}>
              Date: {new Date().toLocaleDateString()}
            </Text>
          </View>

          {/* Table Section */}
          {otherInfo?.currentTab !== "overDue" ? (
            <Text style={styles.sectionTitle}>Product Return Details</Text>
          ) : (
            <Text style={styles.sectionTitle}>All products not expired</Text>
          )}

          {otherInfo?.currentTab !== "overDue" && (
            <View style={styles.table}>
              {/* Table Header */}
              <View style={[styles.tableRow, styles.tableHeader]}>
                <Text style={{ ...styles.tableHead, width: "11%" }}>
                  Invoice No.
                </Text>
                <Text style={{ ...styles.tableHead, width: "10%" }}>CODE</Text>
                <Text style={{ ...styles.tableHead, width: "17%" }}>
                  Description
                </Text>
                <Text style={{ ...styles.tableHead, width: "17%" }}>
                  Importer
                </Text>
                <Text style={{ ...styles.tableHead, width: "9%" }}>
                  Return Policy
                </Text>
                <Text style={{ ...styles.tableHead, width: "10%" }}>
                  Exp. Date
                </Text>
                <Text style={{ ...styles.tableHead, width: "8%" }}>
                  Lot No.
                </Text>
                <Text style={{ ...styles.tableHead, width: "7%" }}>QTY</Text>
                <Text style={{ ...styles.tableHead, width: "11%" }}>
                  Vendor Agreement
                </Text>
              </View>
              {/* Table Body */}
              {allReturnProducts.map((product, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={{ ...styles.tableCell, width: "11%" }}>
                    {product?.IV || "N/A"}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "10%" }}>
                    {product.PARTNAME}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "17%" }}>
                    {product.PARTDES}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "17%" }}>
                    {product.IMPORTER_DES}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "9%" }}>
                    {product.DEXT_CSPOLICYCODE}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "10%" }}>
                    {product?.currentExpiry}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "8%" }}>
                    {product?.lotNo}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "7%" }}>
                    {product?.qty}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "11%" }}>
                    {product?.agreement ? "Yes" : "N/A"}
                  </Text>
                </View>
              ))}
            </View>
          )}
          {otherInfo?.currentTab === "overDue" && (
            <View style={styles.table}>
              {/* Table Header */}
              <View style={[styles.tableRow, styles.tableHeader]}>
                <Text style={{ ...styles.tableHead, width: "11%" }}>
                  Invoice No.
                </Text>
                <Text style={{ ...styles.tableHead, width: "10%" }}>CODE</Text>
                <Text style={{ ...styles.tableHead, width: "17%" }}>
                  Description
                </Text>
                <Text style={{ ...styles.tableHead, width: "17%" }}>
                  Importer
                </Text>
                <Text style={{ ...styles.tableHead, width: "9%" }}>
                  Return Policy
                </Text>
                <Text style={{ ...styles.tableHead, width: "10%" }}>
                  Exp. Date
                </Text>
                <Text style={{ ...styles.tableHead, width: "8%" }}>
                  Lot No.
                </Text>
                <Text style={{ ...styles.tableHead, width: "7%" }}>QTY</Text>
                <Text style={{ ...styles.tableHead, width: "11%" }}>
                  Vendor Agreement
                </Text>
              </View>
              {/* Table Body */}
              {withoutExpiredItems?.map((product, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={{ ...styles.tableCell, width: "11%" }}>
                    {product?.IV || "N/A"}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "10%" }}>
                    {product.PARTNAME}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "17%" }}>
                    {product.PARTDES}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "17%" }}>
                    {product.IMPORTER_DES}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "9%" }}>
                    {product.DEXT_CSPOLICYCODE}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "10%" }}>
                    {product?.currentExpiry}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "8%" }}>
                    {product?.lotNo}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "7%" }}>
                    {product?.qty}
                  </Text>
                  <Text style={{ ...styles.tableCell, width: "11%" }}>
                    {product?.agreement ? "Yes" : "N/A"}
                  </Text>
                </View>
              ))}
            </View>
          )}

          {/* Signature Section */}
          <View style={styles.signatureBlock}>
            <View style={styles.signatureLine}>
              <Text>Vendor name :</Text>
              {/* <Text>{otherInfo?.importerName}</Text> */}
            </View>
            <View style={styles.signatureLine}>
              <Text>Pharmacy name :</Text>
            </View>
          </View>
          <View style={styles.signatureBlock}>
            <View style={styles.signatureLine}>
              <Text>Vendor Signature :</Text>
            </View>
            <View style={styles.signatureLine}>
              <Text>Pharmacy Signature :</Text>
            </View>
          </View>
          {isAgreement && (
            <View style={styles.signatureBlock}>
              <View style={styles.signatureLine}>
                <Text>Importer Name :</Text>
              </View>
              <View style={styles.signatureLine}>
                <Text>Importer Signature :</Text>
              </View>
            </View>
          )}
        </Page>
      )}
      {otherInfo?.currentTab === "overDue" && (
        <Page size="A4" style={styles.page}>
          {/* Header Section */}
          <View style={styles.header}>
            <Text style={styles.headerTextLarge}>Return form</Text>
          </View>
          {/* Customer Info Section */}
          <View style={styles.header}>
            <Text style={styles.headerText}>
              Customer code: {otherInfo.customer.userFullId}
            </Text>
            <Text style={styles.headerText}>
              Serial No.: {otherInfo?.type}
              {otherInfo.serialNo}
            </Text>
          </View>
          <View style={styles.header}>
            <Text style={styles.headerText}>
              Customer Name: {otherInfo.customer.name}
            </Text>
            <Text style={styles.headerText}>
              Date: {new Date().toLocaleDateString()}
            </Text>
          </View>

          {/* Table Section */}
          <Text style={styles.sectionTitle}>All products expired</Text>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={[styles.tableRow, styles.tableHeader]}>
              <Text style={{ ...styles.tableHead, width: "11%" }}>
                Invoice No.
              </Text>
              <Text style={{ ...styles.tableHead, width: "10%" }}>CODE</Text>
              <Text style={{ ...styles.tableHead, width: "17%" }}>
                Description
              </Text>
              <Text style={{ ...styles.tableHead, width: "17%" }}>
                Importer
              </Text>
              <Text style={{ ...styles.tableHead, width: "9%" }}>
                Return Policy
              </Text>
              <Text style={{ ...styles.tableHead, width: "10%" }}>
                Exp. Date
              </Text>
              <Text style={{ ...styles.tableHead, width: "8%" }}>Lot No.</Text>
              <Text style={{ ...styles.tableHead, width: "7%" }}>QTY</Text>
              <Text style={{ ...styles.tableHead, width: "11%" }}>
                Vendor Agreement
              </Text>
            </View>
            {/* Table Body */}
            {expiredItems?.map((product, index) => (
              <View style={styles.tableRow} key={index}>
                <Text style={{ ...styles.tableCell, width: "11%" }}>
                  {product?.IV || "N/A"}
                </Text>
                <Text style={{ ...styles.tableCell, width: "10%" }}>
                  {product.PARTNAME}
                </Text>
                <Text style={{ ...styles.tableCell, width: "17%" }}>
                  {product.PARTDES}
                </Text>
                <Text style={{ ...styles.tableCell, width: "17%" }}>
                  {product.IMPORTER_DES}
                </Text>
                <Text style={{ ...styles.tableCell, width: "9%" }}>
                  {product.DEXT_CSPOLICYCODE}
                </Text>
                <Text style={{ ...styles.tableCell, width: "10%" }}>
                  {product?.currentExpiry}
                </Text>
                <Text style={{ ...styles.tableCell, width: "8%" }}>
                  {product?.lotNo}
                </Text>
                <Text style={{ ...styles.tableCell, width: "7%" }}>
                  {product?.qty}
                </Text>
                <Text style={{ ...styles.tableCell, width: "11%" }}>
                  {product?.agreement ? "Yes" : "N/A"}
                </Text>
              </View>
            ))}
          </View>

          {/* Signature Section */}
          <View style={styles.signatureBlock}>
            <View style={styles.signatureLine}>
              <Text>Vendor name :</Text>
              {/* <Text>{otherInfo?.importerName}</Text> */}
            </View>
            <View style={styles.signatureLine}>
              <Text>Pharmacy name :</Text>
            </View>
          </View>
          <View style={styles.signatureBlock}>
            <View style={styles.signatureLine}>
              <Text>Vendor Signature :</Text>
            </View>
            <View style={styles.signatureLine}>
              <Text>Pharmacy Signature :</Text>
            </View>
          </View>
          {isAgreement && (
            <View style={styles.signatureBlock}>
              <View style={styles.signatureLine}>
                <Text>Importer Name :</Text>
              </View>
              <View style={styles.signatureLine}>
                <Text>Importer Signature :</Text>
              </View>
            </View>
          )}
        </Page>
      )}
    </Document>
  );
};
