import axios from "axios";

export const fetchVendors = async (
  sorting,
  globalFilter,
  columnFilters,
  pagination,
  setData,
  setRowCount,
  setIsError,
  setIsLoading
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}/vendors`);
  url.searchParams.set("page", pagination?.pageIndex);
  url.searchParams.set("size", pagination?.pageSize);
  url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
  url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

  console.log(url, "url vend xx xx");
  console.log(columnFilters, "url vend columnFilters xx");

  try {
    setIsLoading(true);
    const response = await axios.get(url);
    setData(response.data?.data);
    setRowCount(response.data?.totalRows);
  } catch (error) {
    console.error(error);
  } finally {
    setIsLoading(false);
  }
};
