import { Link, useNavigate } from "react-router-dom";
import ReturnProductTable from "../components/ReturnProductTable";
import backLogo from "../assets/back-logo.png";
import { useEffect } from "react";
import { Button } from "@mui/material";

function ReturnProductList({ isVendorName }) {
  const navigate = useNavigate();
  const userFullId = localStorage.getItem("userId");

  useEffect(() => {
    if (userFullId && userFullId.includes("V")) {
      navigate("/app");
    }
  }, [userFullId]);

  return (
    <div>
      <div className="flex gap-1 items-center justify-between py-3 lg:py-10 px-2 bg-white lg:static sticky top-0 left-0 z-[100] lg:shadow-none shadow-md">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <img
            src={backLogo}
            width={25}
            height={10}
            onClick={() => navigate(-1)}
            className="lg:hidden"
          />
          <div className="w-full flex justify-between items-center">
            <h1 className="text-xl md:text-2xl">Return Products</h1>
          </div>
        </div>
        {/* {userFullId !== "S9999" && (
          <Link
            to="/app/return-product"
            className="border px-3 py-2 rounded-md"
          >
            Create Return
          </Link>
        )} */}
        {userFullId !== "S9999" && (
          <div className="flex gap-3">
            <Link to="/app/return-product?type=recall">
              <Button variant="contained">Ανακλήσεις</Button>
            </Link>
            <Link to="/app/return-product?type=normal">
              <Button variant="contained">Επιστροφές 15 ημερών</Button>
            </Link>
            <Link to="/app/return-product?type=overDue">
              <Button variant="contained">Ληξιπρόθεσμες επιστροφές</Button>
            </Link>
          </div>
        )}
      </div>
      <ReturnProductTable isVendorName={isVendorName} />
    </div>
  );
}

export default ReturnProductList;
