import axios from "axios";
import moment from "moment";

export const FetchOrdersData = async (
  sorting,
  globalFilter,
  columnFilters,
  pagination,
  setData,
  setRowCount,
  setIsError,
  monthFilter,
  setIsLoading
) => {
  let userId = localStorage.getItem("userId");

  const url = new URL(
    `${process.env.REACT_APP_API_URL}/fetch-orders?customer_id=${userId}`
  );
  url.searchParams.set("filters", JSON.stringify(columnFilters ?? [])); //[{"id":"PARTNAME","value":"sa"}]
  // url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

  if (monthFilter) {
    url.searchParams.set(
      "monthFilter",
      JSON.stringify([{ id: "monthFilter", value: monthFilter }])
    );
  }

  try {
    const response = await axios.get(url);
    setIsLoading(true);

    if (response?.data?.value) {
      //const json = await response.json();
      const ordersData = response.data.value.map((item) => ({
        ...item,
        TOTPRICE: (parseFloat(item.TOTPRICE) / 100).toFixed(2),
        TOTQUANT: parseFloat(item.TOTQUANT) / 1000,
        DEXT_SUBMISSIONDATE: moment(item.DEXT_SUBMISSIONDATE).format(
          "DD-MM-YYYY"
        ),
      }));
      setData(ordersData);
      setRowCount(ordersData.length);
    } else {
      setData([]);
      setRowCount(0);
    }

  } catch (error) {
    console.error(error);
  }finally{
    setIsLoading(false);
  }
};
