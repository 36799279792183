import { Link, useNavigate } from "react-router-dom";
import backLogo from "../assets/back-logo.png";
import NewCodeTable from "../components/NewCodeTable";
import { useEffect } from "react";

function NewCodeList({ isVendorName }) {
  const navigate = useNavigate();
  const userFullId = localStorage.getItem("userId");
  useEffect(() => {
    if (userFullId && userFullId.includes("C")) {
      navigate("/app");
    }
  }, [userFullId]);

  return (
    <div>
      <div className="flex gap-1 items-center justify-between py-3 lg:py-10 px-2 bg-white lg:static sticky top-0 left-0 z-[100] lg:shadow-none shadow-md">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <img
            src={backLogo}
            width={25}
            height={10}
            onClick={() => navigate(-1)}
            className="lg:hidden"
          />
          <div className="w-full flex justify-between items-center">
            <h1 className="text-xl md:text-2xl">New Code</h1>
          </div>
        </div>
        {userFullId !== "S9999" && (
          <Link
            to="/app/new-code-electronic-form"
            className="border px-3 py-2 rounded-md"
          >
            Add New Code
          </Link>
        )}
      </div>
      <NewCodeTable isVendorName={isVendorName} />
    </div>
  );
}

export default NewCodeList;
