import React, { useEffect, useState } from "react";
import { vatDetailsObject } from "../../../constant/vatDetailsObject";
import { TextField } from "@mui/material";
import cartSession from "./cartSession";
import { useDispatch, useSelector } from "react-redux";
import {
  setItemExtraDiscount,
  setItemExtraFree,
} from "../../../store/reducer/cart";

const CartItems = ({
  discountLabel,
  quantityInputValue,
  handleQuantityChange,
  setQuantityInputValue,
  removeItem,
  item,
  index,
  isVendorName,
}) => {
  const { freeItemQuantity, itemsExtraFree, itemsExtraDiscount, mixMatchItems, mixMatchDiscount } = useSelector(
    (state) => state.cart
  );
  const dispatch = useDispatch();
  const [vatCharge, setVatCharge] = useState(0);
  const [withoutVatPrice, setWithoutVatPrice] = useState(0);
  const userData = localStorage.getItem("userDetails");
  const userDetails = userData ? JSON.parse(userData) : undefined;
  const options = Array.from({ length: 300 }, (_, index) => index + 1);

  useEffect(() => {
    if (item) {
      const tempvatCharge = item
        ? (parseFloat(item?.WSPLPRICE) * 100) /
          (100 + vatDetailsObject[item?.VAT]?.VATPercentage)
        : 0;
      const tempwithoutVatPrice = item
        ? parseFloat(item?.WSPLPRICE) - withoutVatPrice
        : 0;
      setVatCharge(tempvatCharge);
      setWithoutVatPrice(tempwithoutVatPrice);
    }
  }, [item]);

  const handleFreeItem = (value) => {
    const PARTNAME = item?.PARTNAME;
    if (value) {
      const updatedFreeItems = {
        ...itemsExtraFree,
        [PARTNAME]: { quantity: value, WSPLPRICE: item?.WSPLPRICE },
      };
      dispatch(setItemExtraFree({ itemsExtraFree: updatedFreeItems }));
    } else {
      const updatedFreeItems = { ...itemsExtraFree };
      delete updatedFreeItems[PARTNAME];
      dispatch(setItemExtraFree({ itemsExtraFree: updatedFreeItems }));
    }
  };

  const handleFreeDiscount = (value) => {
    const PARTNAME = item?.PARTNAME;
    if (value) {
      const updatedData = {
        quantity: item?.quantity || quantityInputValue[item.PARTNAME] || 1,
        percent: value,
        WSPLPRICE: item?.WSPLPRICE,
      };
      const updatedFreeDiscount = {
        ...itemsExtraDiscount,
        [PARTNAME]: updatedData,
      };
      dispatch(
        setItemExtraDiscount({ itemsExtraDiscount: updatedFreeDiscount })
      );
    } else {
      const updatedFreeDiscount = { ...itemsExtraDiscount };
      delete updatedFreeDiscount[PARTNAME];
      dispatch(
        setItemExtraDiscount({ itemsExtraDiscount: updatedFreeDiscount })
      );
    }
  };

  return (
    <>
      {window.innerWidth > 1024 && (
        <tr key={index}>
          <td className="py-2">
            <p class="cart-item-name">{item?.PARTDES}</p>
            {freeItemQuantity && freeItemQuantity[item.PARTNAME] && (
              <p class="cart-item-discount">
                {freeItemQuantity[item.PARTNAME]?.selectedDiscount?.OFFERDES}
              </p>
            )}
            {mixMatchItems && mixMatchItems[item.PARENT_PARTNAME] && mixMatchDiscount[item.PARENT_PARTNAME] && (
              <p class="cart-item-discount">
                {mixMatchItems[item.PARENT_PARTNAME][0]?.selectedDiscount?.OFFERDES}
              </p>
            )}
          </td>
          <td className="py-2">
            {" "}
            <p class="cart-item-name">{item?.PARTNAME}</p>
          </td>
          <td className="py-2">
            <p className="cart-item-price">
              {Number(item?.WSPLPRICE).toFixed(2)}
            </p>
          </td>
          <td className="py-2">
            {item?.VAT ? (
              <p className="cart-item-price">
                {vatDetailsObject[item?.VAT]?.VATPercentage}%
              </p>
            ) : (
              <p className="cart-item-price">-</p>
            )}
          </td>
          <td className="py-2">
            {item?.VAT ? (
              <p className="cart-item-price">
                {(
                  Number(item?.WSPLPRICE) *
                  (vatDetailsObject[item?.VAT]?.VATPercentage / 100)
                ).toFixed(2)}
              </p>
            ) : (
              <p className="cart-item-price">-</p>
            )}
          </td>

          <td className="py-2">
            {item?.VAT ? (
              <p class="cart-item-price">
                {(
                  Number(item?.WSPLPRICE || 0) +
                  Number(item?.WSPLPRICE) *
                    (vatDetailsObject[item?.VAT]?.VATPercentage / 100)
                ).toFixed(2)}
              </p>
            ) : (
              <p class="cart-item-price">
                {Number(item?.WSPLPRICE).toFixed(2)}
              </p>
            )}
          </td>

          <td className="py-2">
            <p class="cart-item-name">
              {(
                Number(item?.WSPLPRICE) *
                (item?.quantity || quantityInputValue[item.PARTNAME] || 1)
              ).toFixed(2)}
            </p>
          </td>
          <td className="flex justify-center py-2">
            <div class="cart-item-controls gap-1">
              {window.innerWidth > 1024 ? (
                <>
                  <button
                    style={{
                      width: "38px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      padding: "2px",
                      fontSize: "1.2rem",
                      textAlign: "center",
                      fontWeight: "bold",
                      backgroundColor: "#f2f2f2",
                    }}
                    onClick={(e) => {
                      if (
                        item?.quantity === 1 ||
                        quantityInputValue[item.PARTNAME] === 1
                      )
                        return;
                      handleQuantityChange(
                        item?.quantity
                          ? Number(item?.quantity) - 1
                          : Number(quantityInputValue[item.PARTNAME]) - 1,
                        item,
                        index,
                        setQuantityInputValue
                      );
                    }}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={
                      item?.quantity || quantityInputValue[item.PARTNAME] || 1
                    }
                    min={
                      parseInt(item.DEXT_LOWSTOCKQTY) > 0
                        ? item.DEXT_LOWSTOCKQTY
                        : 1
                    }
                    // max={item.stock}
                    onChange={(e) =>
                      handleQuantityChange(
                        e.target.value,
                        item,
                        index,
                        setQuantityInputValue
                      )
                    }
                    style={{
                      width: "40px",
                      // marginRight: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      padding: "5px",
                      fontSize: "1rem",
                      textAlign: "center",
                    }}
                  />
                  <button
                    style={{
                      width: "38px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      padding: "3px",
                      fontSize: "1.1rem",
                      textAlign: "center",
                      fontWeight: "bold",
                      backgroundColor: "#f2f2f2",
                    }}
                    onClick={(e) => {
                      handleQuantityChange(
                        item?.quantity
                          ? Number(item?.quantity) + 1
                          : Number(quantityInputValue[item.PARTNAME]) + 1,
                        item,
                        index,
                        setQuantityInputValue
                      );
                    }}
                  >
                    +
                  </button>
                </>
              ) : (
                <select
                  value={
                    item?.quantity || quantityInputValue[item.PARTNAME] || 1
                  }
                  style={{
                    width: "55px",
                    marginRight: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    fontSize: "1rem",
                    textAlign: "center",
                  }}
                  onChange={(e) =>
                    handleQuantityChange(
                      e.target.value,
                      item,
                      index,
                      setQuantityInputValue
                    )
                  }
                >
                  {options.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              )}
              <button
                class="cart-item-remove-btn"
                onClick={() => removeItem(item)}
              >
                &times;
              </button>
            </div>
          </td>
        </tr>
      )}
      {window.innerWidth > 1024 && (
        <tr style={{ borderBottom: "1px solid #f2f2f2" }}>
          <div style={{ paddingBottom: 8 }}>
            {isVendorName !== "" && userDetails?.DEXT_CANOFFER === "Y" && (
              <div className="flex gap-2">
                <TextField
                  className="free-qty"
                  size="small"
                  label="Extra Free Quantity"
                  variant="outlined"
                  fullWidth="true"
                  disabled={itemsExtraDiscount[item.PARTNAME]}
                  value={
                    itemsExtraFree[item.PARTNAME]
                      ? itemsExtraFree[item.PARTNAME]?.quantity
                      : ""
                  }
                  type="number"
                  onChange={(e) => {
                    if (e.target.value > -1) {
                      handleFreeItem(e.target.value);
                    }
                  }}
                />
                <TextField
                  className="discount"
                  size="small"
                  label="Additional Discount (%)"
                  variant="outlined"
                  fullWidth="true"
                  disabled={itemsExtraFree[item.PARTNAME]}
                  value={
                    itemsExtraDiscount[item.PARTNAME]
                      ? itemsExtraDiscount[item.PARTNAME]?.percent
                      : ""
                  }
                  type="number"
                  onChange={(e) => {
                    if (e.target.value > -1 && e.target.value < 101) {
                      handleFreeDiscount(e.target.value);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </tr>
      )}

      {window.innerWidth <= 1024 && (
        <div
          className="cart-item border-b-0 pb-0"
          style={{ borderTop: "1px solid #f2f2f2" }}
        >
          <div class="cart-item-details">
            <p class="cart-item-name">{item?.PARTDES}</p>
            <p class="cart-item-name">Code: {item?.PARTNAME}</p>
            <p className="cart-item-price">
              Wholesale: {Number(item?.WSPLPRICE).toFixed(2)}
            </p>
            {item?.VAT && (
              <p className="cart-item-price">
                VAT({vatDetailsObject[item?.VAT]?.VATPercentage}%):{" "}
                {(
                  Number(item?.WSPLPRICE) *
                  (vatDetailsObject[item?.VAT]?.VATPercentage / 100)
                ).toFixed(2)}
              </p>
            )}
            {item?.VAT ? (
              <p class="cart-item-price">
                Price:{" "}
                {(
                  Number(item?.WSPLPRICE || 0) +
                  Number(item?.WSPLPRICE) *
                    (vatDetailsObject[item?.VAT]?.VATPercentage / 100)
                ).toFixed(2)}
              </p>
            ) : (
              <p class="cart-item-price">
                Price: {Number(item?.WSPLPRICE).toFixed(2)}
              </p>
            )}
            {freeItemQuantity && freeItemQuantity[item.PARTNAME] && (
              <p class="cart-item-discount">
                Discount:{" "}
                {freeItemQuantity[item.PARTNAME]?.selectedDiscount?.OFFERDES}
              </p>
            )}
          </div>
          <div class="cart-item-controls gap-1">
            {window.innerWidth > 1024 ? (
              <>
                <button
                  style={{
                    width: "38px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    padding: "2px",
                    fontSize: "1.2rem",
                    textAlign: "center",
                    fontWeight: "bold",
                    backgroundColor: "#f2f2f2",
                  }}
                  onClick={(e) => {
                    if (
                      item?.quantity === 1 ||
                      quantityInputValue[item.PARTNAME] === 1
                    )
                      return;
                    handleQuantityChange(
                      item?.quantity
                        ? Number(item?.quantity) - 1
                        : Number(quantityInputValue[item.PARTNAME]) - 1,
                      item,
                      index,
                      setQuantityInputValue
                    );
                  }}
                >
                  -
                </button>
                <input
                  type="number"
                  value={
                    item?.quantity || quantityInputValue[item.PARTNAME] || 1
                  }
                  min={
                    parseInt(item.DEXT_LOWSTOCKQTY) > 0
                      ? item.DEXT_LOWSTOCKQTY
                      : 1
                  }
                  // max={item.stock}
                  onChange={(e) =>
                    handleQuantityChange(
                      e.target.value,
                      item,
                      index,
                      setQuantityInputValue
                    )
                  }
                  style={{
                    width: "40px",
                    // marginRight: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    fontSize: "1rem",
                    textAlign: "center",
                  }}
                />
                <button
                  style={{
                    width: "38px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    padding: "3px",
                    fontSize: "1.1rem",
                    textAlign: "center",
                    fontWeight: "bold",
                    backgroundColor: "#f2f2f2",
                  }}
                  onClick={(e) => {
                    handleQuantityChange(
                      item?.quantity
                        ? Number(item?.quantity) + 1
                        : Number(quantityInputValue[item.PARTNAME]) + 1,
                      item,
                      index,
                      setQuantityInputValue
                    );
                  }}
                >
                  +
                </button>
              </>
            ) : (
              <select
                value={item?.quantity || quantityInputValue[item.PARTNAME] || 1}
                style={{
                  width: "55px",
                  marginRight: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  padding: "5px",
                  fontSize: "1rem",
                  textAlign: "center",
                }}
                onChange={(e) =>
                  handleQuantityChange(
                    e.target.value,
                    item,
                    index,
                    setQuantityInputValue
                  )
                }
              >
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            )}
            <button
              class="cart-item-remove-btn"
              onClick={() => removeItem(item)}
            >
              &times;
            </button>
          </div>
        </div>
      )}
      {isVendorName !== "" &&
        userDetails?.DEXT_CANOFFER === "Y" &&
        window.innerWidth <= 1024 && (
          <div className="flex gap-2 pb-2">
            <TextField
              className="free-qty"
              size="small"
              label="Extra Free Quantity"
              variant="outlined"
              fullWidth="true"
              disabled={itemsExtraDiscount[item.PARTNAME]}
              value={
                itemsExtraFree[item.PARTNAME]
                  ? itemsExtraFree[item.PARTNAME]?.quantity
                  : ""
              }
              type="number"
              onChange={(e) => {
                if (e.target.value > -1) {
                  handleFreeItem(e.target.value);
                }
              }}
            />
            <TextField
              className="discount"
              size="small"
              label="Additional Discount (%)"
              variant="outlined"
              fullWidth="true"
              disabled={itemsExtraFree[item.PARTNAME]}
              value={
                itemsExtraDiscount[item.PARTNAME]
                  ? itemsExtraDiscount[item.PARTNAME]?.percent
                  : ""
              }
              type="number"
              onChange={(e) => {
                if (e.target.value > -1 && e.target.value < 101) {
                  handleFreeDiscount(e.target.value);
                }
              }}
            />
          </div>
        )}
    </>
  );
};

export default CartItems;
