import { paymentTermsObject } from "../../constant/paymentTerms";

export const INVOICE_COLUMNS = [
  {
    header: "Invoice Num",
    accessorKey: "IVNUM",
    enableSorting: true,
  },
  {
    header: "Invoice Reference",
    accessorKey: "DEXT_VENDORINVREF",
    enableSorting: true,
  },
  {
    header: "Details",
    accessorKey: "DETAILS",
    enableColumnFilter: false,
    enableSorting: true,
  },
  {
    header: "Payment terms",
    accessorKey: "PAYCODE",
    enableColumnFilter: false,
    enableSorting: true,
    Cell: ({ cell }) => paymentTermsObject[cell.row.original.PAYCODE],
  },
  {
    header: "Vendor name",
    accessorKey: "DEXT_VENDOR_DESC",
    enableSorting: true,
  },
  // {
  //   header: "Vendor comments",
  //   accessorKey: "DEXT_VENTORREMARKS",
  //   enableColumnFilter: false,
  //   enableSorting: true,
  // },
  {
    header: "Quantity",
    accessorKey: "TOTQUANT",
    enableColumnFilter: false,
    enableSorting: true,
    Cell: ({ cell }) => cell.row.original.TOTQUANT/1000,
  },
  {
    header: "Total amount",
    accessorKey: "TOTPRICE",
    enableColumnFilter: false,
    enableSorting: true,
    Cell: ({ cell }) => cell.row.original.TOTPRICE/100,
  },
  {
    header: "Date",
    accessorKey: "IVDATE",
    enableColumnFilter: false,
  },
];
