export const getMixMatchDiscount = async (
  allDiscount,
  allMixMatchItems,
  selectedMixMatchDiscount
) => {
  // TOTAL QUANTITY OF SELECTED MIXMATCH ITEMS
  const totalAddedMixMatchItems =
    allMixMatchItems?.reduce(
      (total, item) => total + (Number(item.quantity) || 1),
      0
    ) || 0;

  // TOTAL PRICE OF SELECTED MIXMATCH ITEMS
  const selectedMixMatchItemsPrice =
    allMixMatchItems
      ?.reduce((total, item) => {
        const quantity = Number(item.quantity) || 0;
        const wsplPrice = Number(item.WSPLPRICE) || 0;
        return total + quantity * wsplPrice;
      }, 0)
      .toFixed(2) || 0.0;

  let discountApplicableonMixMatch = 0;

  if (allMixMatchItems.length > 1) {
    if (selectedMixMatchDiscount) {
      discountApplicableonMixMatch =
        (selectedMixMatchItemsPrice *
          Number(selectedMixMatchDiscount.DISCOUNT)) /
        100;
    } else {
      const applicableDiscounts = allDiscount.filter(
        (item) =>
          Number(item.DEXT_OFFERCODE) === 4 &&
          totalAddedMixMatchItems >= Number(item.OFFERQTY)
      );

      if (applicableDiscounts.length > 0) {
        const sortedApplicableDiscounts = applicableDiscounts.sort(
          (a, b) => Number(b.OFFERQTY) - Number(a.OFFERQTY)
        );

        const highestApplicableDiscount = sortedApplicableDiscounts.find(
          (item) => totalAddedMixMatchItems >= Number(item.OFFERQTY)
        );

        if (highestApplicableDiscount) {
          discountApplicableonMixMatch =
            (selectedMixMatchItemsPrice *
              Number(highestApplicableDiscount.DISCOUNT)) /
            100;
        }
      }
    }
  }

  return {
    totalAddedMixMatchItems,
    selectedMixMatchItemsPrice,
    discountApplicableonMixMatch,
  };
};
