import moment from "moment";
import FlagIcon from "@mui/icons-material/Flag";
import { Button } from "@mui/material";
import warningIcon from "../assets/warning-icon.png";

const now = moment();
const userId = localStorage.getItem("userId");

export const COLUMNS = [
  {
    header: "ID",
    accessorKey: "custom_id",
    enableColumnFilter: false,
    size: 3,
  },
  {
    header: "Invoice No.",
    accessorKey: "invoiceNo",
    enableColumnFilter: false,
    size: 6,
    Cell: ({ cell }) => (
      <p className="part-description">{cell.row.original.invoiceNo || "-"}</p>
    ),
  },
  {
    header: "Date",
    accessorKey: "created_at",
    enableColumnFilter: false,
    size: 5,
  },
  {
    header: "Total Products",
    accessorKey: "total_products",
    enableColumnFilter: false,
  },
  {
    header: "User ID",
    accessorKey: "user_id",
    size: 5,
  },
  {
    header: "FIRM",
    accessorKey: "firm",
    size: 8,
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 5,
    enableColumnFilter: false,
    Cell: ({ cell }) => {
      const isAllItemProcessed = cell.row.original.details.some(
        (item) => item?.status === "Pending"
      );
      return (
        <p
          className={
            cell.row.original.status === "Pending"
              ? "text-red-600 flex gap-1"
              : "text-green-600 flex gap-1"
          }
        >
          {cell.row.original.status}
          {isAllItemProcessed && cell.row.original.status !== "Pending" && (
            <img src={warningIcon} width={18} height={18} alt="warning" />
          )}
        </p>
      );
    },
  },
];
